import React, { useEffect, useState } from "react";

const MAX_MOBILE_SIZE = "480";
const MAX_TABLET_SIZE = "1024";
export const DESKTOP = "DESKTOP";
export const TABLET = "TABLET";
export const MOBILE = "MOBILE";
export const INDETERMINATE = "INDETERMINATE";

const useMediaQuery = () => {
  const [largerThanMobile, setLargerThanMobile] = useState(false);
  const [largerThanTablet, setLargerThanTablet] = useState(false);

  const getSize = () => {
    if (largerThanMobile && largerThanTablet) return DESKTOP;
    else if (largerThanMobile && !largerThanTablet) return TABLET;
    else if (!largerThanMobile && !largerThanTablet) return MOBILE;
    else return INDETERMINATE;
  };

  useEffect(() => {
    window
      .matchMedia(`(min-width: ${MAX_MOBILE_SIZE}px)`)
      .addEventListener("change", (e) => {
        setLargerThanMobile(e.matches);
        console.log("> mobile");
      });

    window
      .matchMedia(`(min-width: ${MAX_TABLET_SIZE}px)`)
      .addEventListener("change", (e) => {
        setLargerThanTablet(e.matches);
        console.log("> tablet");

      });

    const width =  window.innerWidth;
    if(width < MAX_MOBILE_SIZE ){
      setLargerThanMobile(false);
      setLargerThanTablet(false);
    } else if((width > MAX_MOBILE_SIZE) && (width < MAX_TABLET_SIZE )) {
      setLargerThanMobile(true);
      setLargerThanTablet(false);
    } else if((width > MAX_TABLET_SIZE)) {
      setLargerThanMobile(true);
      setLargerThanTablet(true);
    } else {
      console.log("Error in computing resolution");
    }
  }, []);

  return getSize();
};

export default useMediaQuery;
