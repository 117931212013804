import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    firstname: "",
    lastname: "",
    address: "",
    pincode: "",
    suburb: "",
    userstate: "",
  },
};

const globalSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, actions) => {
      state.user = actions.payload;
    },
  },
});

export const { setUser } = globalSlice.actions;

export default globalSlice.reducer;
