import { createSlice } from "@reduxjs/toolkit";
import {
  LOGIN_MODAL,
  SIGN_UP_MODAL,
  UPDATE_EMAIL_MODAL,
  UPDATE_PERSONAL_DETAILS_MODAL,
  UPDATE_PHONE_MODAL,
  UPLOAD_PROFILE_IMAGE_MODAL,
  WELCOME_MODAL,
} from "../modal-constants";
import {
  PROFILE_INFO,
  UPDATE_PHONE_NUMBER,
  UPLOAD_PHOTO,
  VERIFY_EMAIL,
} from "../../components/settingshomescreen/utils/constants";

const initialState = {
  visibleModal: undefined,
  modalData: {},
  messageModal: false,
  messageModalData: {},
  addNewSkillModal: false,
  addNewSkillModalData: {},
};

const globalModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    displayModal: (state, actions) => {
      state.visibleModal = actions.payload.modal;
      state.modalData = actions.payload.data;
    },
    closeModal: (state) => {
      state.visibleModal = undefined;
      state.modalData = {};
    },
    displayMessageModal: (state, actions) => {
      state.messageModal = true;
      state.messageModalData = actions.payload;
    },
    closeMessageModal: (state) => {
      state.messageModal = undefined;
      state.messageModalData = {};
    },
    displayWelcomeModal: (state) => {
      state.visibleModal = WELCOME_MODAL;
    },
    displaySignUpModal: (state, actions) => {
      state.visibleModal = SIGN_UP_MODAL;
      state.signUpModalData = actions.payload;
    },
    hideSignUpModal: (state) => {
      state.visibleModal = undefined;
      state.signUpModalData = {};
    },
    displayLogInModal: (state, actions) => {
      state.visibleModal = LOGIN_MODAL;
      state.logInModalData = actions.payload;
    },
    hideLogInModal: (state) => {
      state.visibleModal = undefined;
      state.logInModalData = {};
    },
    displayAddNewSkillModal: (state, actions) => {
      state.addNewSkillModal = true;
      state.addNewSkillModalData = actions.payload;
    },
    hideAddNewSkillModal: (state) => {
      state.addNewSkillModal = false;
      state.addNewSkillModalData = {};
    },
    handleSettingsButtonClick: (state, actions) => {
      switch (actions.payload) {
        case PROFILE_INFO:
          state.visibleModal = UPDATE_PERSONAL_DETAILS_MODAL;
          break;
        case UPDATE_PHONE_NUMBER:
          state.visibleModal = UPDATE_PHONE_MODAL;
          break;
        case VERIFY_EMAIL:
          state.visibleModal = UPDATE_EMAIL_MODAL;
          break;
        case UPLOAD_PHOTO:
          state.visibleModal = UPLOAD_PROFILE_IMAGE_MODAL;
      }
    },
  },
});

export const {
  displayModal,
  closeModal,
  displayMessageModal,
  closeMessageModal,
  displaySignUpModal,
  hideSignUpModal,
  displayLogInModal,
  hideLogInModal,
  displayAddNewSkillModal,
  hideAddNewSkillModal,
  displayWelcomeModal,
  handleSettingsButtonClick,
} = globalModalSlice.actions;

export default globalModalSlice.reducer;
