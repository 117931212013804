/* eslint-disable default-case */
import {
  UPDATE_PHONE_NUMBER,
  MY_LISTINGS,
  PROFILE_INFO,
  VERIFY_EMAIL,
  UPLOAD_PHOTO,
} from "./constants";

export const getTitle = (index) => {
  switch (index) {
    case PROFILE_INFO:
      return "Profile";
    case UPDATE_PHONE_NUMBER:
      return "Phone Number";
    case MY_LISTINGS:
      return "My Listings";
    case VERIFY_EMAIL:
      return "Verify Email";
    case UPLOAD_PHOTO:
      return "Profile Image";
  }
};

export const getDesc = (index) => {
  switch (index) {
    case PROFILE_INFO:
      return "Provide your name, address and related info";
    case UPDATE_PHONE_NUMBER:
      return "Update your phone number";
    case MY_LISTINGS:
      return "Manage your listings";
    case VERIFY_EMAIL:
      return "Verify your email address";
    case UPLOAD_PHOTO:
      return "Upload your profile image";
  }
};

export const getIcon = (index) => {
  switch (index) {
    case PROFILE_INFO:
      return <i className="fa-solid fa-address-card"></i>;
    case UPDATE_PHONE_NUMBER:
      return <i className="fa-solid fa-square-phone"></i>;
    case MY_LISTINGS:
      return <i className="fa-solid fa-list-ul"></i>;
    case VERIFY_EMAIL:
      return <i className="fa-solid fa-envelope"></i>;
    case UPLOAD_PHOTO:
      return <i className="fa-solid fa-user"></i>;
  }
};
