import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  saveJobImagePrimaryURL,
  saveUserDetails,
  saveUserImageURL,
} from "../../state/slices/cacheslice";
import { useDispatch, useSelector } from "react-redux";
import { getAdvtImageSrcURL, getProfileImageSrcURL } from "../../firestorage";
import { JOB } from "../../firedb";

//https://stackoverflow.com/questions/71013399/react-firebase-read-data-results-in-too-many-renders

const useJobImageURL = (jobUUID) => {
  const dispatch = useDispatch();

  const imageURL = useSelector((state) => state?.cache?.jobImage?.[jobUUID]);

  useEffect(() => {
    const fn = async (jobUUID) => {
      try {
        const jobDetails = await new Promise((resolve, reject) => {
          JOB.getJob(
            `POSTED_JOBLIST/${jobUUID}`,
            (jobDetails) => {
              resolve(jobDetails.val());
            },
            () => {
              reject("couldnt get job details");
            }
          );
        });

        const imageJSON = JSON.parse(jobDetails.imageFileNames);

        const primaryImageURL = await new Promise((resolve, reject) => {
          if (!imageJSON.primary) {
            reject("no image details in job details");
          } else if (!jobDetails.storageUUID) {
            reject("no storage uuid in job details");
          } else {
            getAdvtImageSrcURL(
              imageJSON.primary,
              jobDetails.storageUUID,
              (url) => {
                resolve(url);
              },
              (reason) => {
                reject(reason);
              }
            );
          }
        });

        dispatch(saveJobImagePrimaryURL({ jobUUID, primaryImageURL }));
      } catch (error) {
        console.error(error);
      }
    };

    fn(jobUUID);
  }, []);

  return imageURL;
};

export default useJobImageURL;
