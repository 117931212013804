import React from "react";
import Chat from "../chat";
import "./styles.scss";

const MessageHomeScreen = () => {
  return (
    <div className="message-home-screen__container">
      <div className="generic-container">
        <Chat />
      </div>
    </div>
  );
};

export default MessageHomeScreen;
