import React from "react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHAT } from "../../../firedb";
import { getAdvtImageListURL } from "../../../firestorage";
import "../../../skeleton-modal.scss";
import { closeModal, displayMessageModal } from "../../../state/slices/globalmodalslice";
import { setLoading } from "../../../state/slices/globalslice";
import { setHomePageContent } from "../../../state/slices/homepageslice";
import { MESSAGE_PAGE } from "../../../utils/constants";
import ImageSlider from "../../imageslider";
import "./styles.scss";
import { createNewChat } from "../../../firecloudfn/onRequest";

const JobListingModal = () => {
  const [setOfAdvtImages, setSetOfAdvtImages] = useState([]);
  let payment;
  const dispatch = useDispatch();
  const [loadingAdvtImg, setLoadingAdvtImg] = useState(false);

  const jobPostingData = useSelector((state) => state?.modals?.modalData);
  const storageUUID = jobPostingData.storageUUID;

  useEffect(() => {
    setLoadingAdvtImg(true);

    getAdvtImageListURL(storageUUID).then((setOfPromises) => {
      Promise.all(setOfPromises).then((setOfImages) => {
        const _setOfImages = setOfImages.map((value) => {
          return { src: value };
        });
        setSetOfAdvtImages(_setOfImages);
        setLoadingAdvtImg(false);
      });
    });
  }, [storageUUID]);

  switch (jobPostingData?.payment?.paymentType) {
    case "FixedPayment":
      {
        if (jobPostingData?.payment?.paymentPerTime === "PerWeek") {
          payment = `${jobPostingData?.payment?.paymentAmount} / per week`;
        }
        if (jobPostingData?.payment?.paymentPerTime === "PerHour") {
          payment = `$ ${jobPostingData?.payment?.paymentAmount} / per hour`;
        }
        if (jobPostingData?.payment?.paymentPerTime === "LumpSum") {
          payment = `$ ${jobPostingData?.payment?.paymentAmount}`;
        }
      }
      break;
    case "DoNotMentionPayment":
      payment = undefined;
      break;
    case "NegotiablePayment":
      payment = "Negotiable";
      break;

    default:
  }

  const { jobTitle, jobDesc } = jobPostingData;
  return (
    <div className="modal modal-open">
      <div className="modal-inner">
        <div className="modal-content">
          <div
            onClick={() => {
              dispatch(closeModal());
            }}
            className="modal-close-icon"
          >
            <a className="close-modal">
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <div className="modal-content-inner">
            <div className="job-modal__header"> {jobTitle} </div>
            <ImageSlider loading={loadingAdvtImg} slides={setOfAdvtImages} />
            <div className="job-modal__info_container">
              <div className="job-modal__item_container">
                <div className="job-modal__payment1"></div>
                <div className="job-modal__payment2">{payment}</div>
              </div>
              <div className="job-modal__item_container">
                <div className="job-modal__postedby1">Posted By</div>
                <div className="job-modal__postedby2">
                  {jobPostingData.createdBy.user.details.firstname +
                    " " +
                    jobPostingData.createdBy.user.details.lastname}
                </div>
                <div className="job-modal__postedby3">
                  {moment(jobPostingData.creationTime).fromNow()}
                </div>
              </div>
            </div>
            <div className="job-modal__desc"> {jobDesc}</div>
          </div>
          <div className="add-skill-select-container">
            <div className="update-profile-explain-modal">
              <button
                onClick={() => {
                  if (
                    (jobPostingData.jobUUID, jobPostingData.createdBy.user.uid)
                  ) {
                    createNewChat(
                      {
                        jobUUID: jobPostingData.jobUUID,
                        person2: jobPostingData.createdBy.user.uid,
                      },

                      (response) => {

                        if(response.headers === 500 && response.body)
                        {
                          dispatch(displayMessageModal({message:"chat already exists"}));
                        }


                        // dispatch(
                        //   setLoading({
                        //     state: true,
                        //     message: "Connecting you...",
                        //   })
                        // );

                        // setTimeout(() => {
                        //   dispatch(closeModal());
                        //   dispatch(setLoading({ state: false }));
                        //   dispatch(setHomePageContent(MESSAGE_PAGE));
                        // }, 1000);
                      },
                      (error) => {

                        if(error.response.status === 500 && error.response.data === "chat already exists")
                        {
                          dispatch(displayMessageModal({message:"chat already exists"}));
                        }


                        // dispatch(
                        //   setLoading({
                        //     state: true,
                        //     message: "Connecting you...",
                        //   })
                        // );

                        // setTimeout(() => {
                        //   dispatch(closeModal());
                        //   dispatch(setLoading({ state: false }));
                        //   dispatch(setHomePageContent(MESSAGE_PAGE));
                        // }, 1000);
                      },
                    );
                  }

                  // CHAT.createNewChat(
                  //   {
                  //     person2: { uuid: jobPostingData.createdBy.user.uuid },
                  //     jobDetails: jobPostingData,
                  //   },
                  //   () => {
                  //     dispatch(
                  //       setLoading({
                  //         state: true,
                  //         message: "Connecting you...",
                  //       })
                  //     );

                  //     setTimeout(() => {
                  //       dispatch(closeModal());
                  //       dispatch(setLoading({ state: false }));
                  //       dispatch(setHomePageContent(MESSAGE_PAGE));
                  //     }, 1000);
                  //   }
                  // );
                }}
                className="button button-primary extra-margin"
              >
                Message
              </button>
            </div>

            <div className="or-button">-OR-</div>
            <div className="update-profile-explain-modal">
              <button
                onClick={() => {}}
                className="button button-primary extra-margin"
              >
                Do something else
              </button>
              <div className="update-profile-explain-text"></div>
            </div>
          </div>

          <hr className="modal-buttons-seperator" />
        </div>
      </div>
    </div>
  );
};

export default JobListingModal;
