import React, { useEffect, useState } from "react";
import "./ListOfJobs.scss";
import { PROVIDE_HOME, LIST_OF_JOBS_BREADCRUMB } from "../utils/constants";
import { useDispatch } from "react-redux";
import { setProvidePageBreadcrumb } from "../../../state/slices/homepageslice";
import { getNearJobs } from "../../../firecloudfn/onRequest";
import BackButton from "../../backbutton";
import Header from "../../header";
import JobListItemSmall from "../../joblistitemsmall/JobListItemSmall";

const ListOfJobs = () => {
  const dispatch = useDispatch();
  const [jobsInArea, setJobsInArea] = useState([]);

  useEffect(() => {
    getNearJobs((response) => {
      const values = Object.values(response);
      setJobsInArea(values);
    });
  }, []);

  const backOnClickHandler = () => {
    dispatch(setProvidePageBreadcrumb(PROVIDE_HOME));
  };

  return (
    <div className="list-of-jobs__container">
      <div className="generic-container__header-bar">
        <BackButton onClick={backOnClickHandler} />
        <Header title="Jobs Posted" />
      </div>

      <div className="provide-home-screen__job-container">
        {jobsInArea.map((job) => (
          <JobListItemSmall job={job} />
        ))}
      </div>
    </div>
  );
};

export default ListOfJobs;
