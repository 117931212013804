import React from "react";
import "./styles.scss";

const BottomBarWithLove = () => {
  return (
    <div className="bottom-bar-message">
      <div></div>
      <div>
        <span>Made with Love</span> <i className="fa-solid fa-heart"></i>
        <span> in the suburbs of Australia</span>
      </div>
      <div className="feedback">
        <span>Feedback</span>
        <i className="fa-solid fa-thumbs-up"></i>
        <i className="fa-solid fa-thumbs-down"></i>
      </div>
    </div>
  );
};

export default BottomBarWithLove;
