import React, { useState } from "react";
import "./styles.scss";
import JobList from "../../utils/joblist";
import { displayAddNewSkillModal } from "../../state/slices/globalmodalslice";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// inspired by https://codepen.io/bugrakocak/pen/EMbKoB

const SearchDropDown = (props) => {
  const dispatcher = useDispatch();

  const {
    clearOnSelect,
    selectedItem,
    value,
    onChangeHandler,
    createSkillOption = true,
    className,
  } = props;

  const [filteredList, setFilteredList] = useState([]);
  const [caratPosition, setCaratPostion] = useState(-1);

  const createNewGlobalSkill = (skill) => {
    //display a model asking
    if (createSkillOption) {
      dispatcher(displayAddNewSkillModal({ skillName: skill }));
    }
  };

  const removeListOnSelect = () => {
    setFilteredList([]);
  };

  const onClickHandler = (e, element) => {
    if (clearOnSelect) {
      onChangeHandler("");
    } else {
      onChangeHandler(element);
    }
    selectedItem(element);
    removeListOnSelect();
  };

  const onInputHandler = (e) => {
    const userInput = e.target?.value?.toLowerCase();

    if (userInput.length === 0) {
      setFilteredList([]);
      setCaratPostion(-1);
      return;
    }

    const filteredWords = JobList.filter((word) =>
      word.toLowerCase().includes(userInput)
    )
      .sort()
      .splice(0, 5);

    setFilteredList(filteredWords);
  };

  const onKeyDownHandler = (e) => {
    const keyvalue = e?.key;

    switch (keyvalue) {
      case "ArrowDown":
      case "ArrowRight":
        e.preventDefault();
        setCaratPostion((caret) => (caret + 1) % filteredList.length);
        break;

      case "ArrowUp":
      case "ArrowLeft":
        e.preventDefault();
        setCaratPostion((caret) => {
          if (caret === 0) {
            return filteredList.length - 1;
          }
          return (caret - 1) % filteredList.length;
        });

        break;

      case "Enter":
        if (caratPosition !== -1 && filteredList.length > 0) {
          const selectedElement = filteredList[caratPosition];
          selectedItem(selectedElement);
          if (clearOnSelect) {
            onChangeHandler("");
          } else {
            onChangeHandler(selectedElement);
          }
          removeListOnSelect();
        } else if (filteredList.length === 0) {
          //no job discreption exists
          createNewGlobalSkill(e.target.value);
        }
        e.preventDefault();

        break;
      case "Escape":
        document.getElementById("drop-down-search").value = "";
        break;

      default:
    }
  };

  return (
    <div className="drop-down-search-container">
      <input
        className={clsx(className)}
        id="drop-down-search"
        type="text"
        placeholder="Search for a skill"
        onInput={onInputHandler}
        onKeyDown={onKeyDownHandler}
        value={value}
        onChange={(e) => {
          onChangeHandler(e.target.value);
        }}
        autoComplete="off"
      />
      <ul class="drop-down-list">
        {filteredList.map((element, index) => (
          <li
            onClick={(e) => onClickHandler(e, element)}
            className={
              caratPosition === index
                ? "list-item-drop-down-search list-item-drop-down-search-highlight"
                : "list-item-drop-down-search"
            }
          >
            {element}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchDropDown;
