// https://freefrontend.com/css-tab-bars/
// https://codepen.io/havardob/pen/ExVaELV

import React, { useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { HOME_PAGE, MESSAGE_PAGE, PROVIDE_PAGE } from "../../utils/constants";
import { setHomePageContent } from "../../state/slices/homepageslice";

const NavBarV2 = () => {
  const dispatch = useDispatch();
  const [a, setA] = useState(0);
  const [b, setB] = useState(0);
  const [selectedRadio, setSelectedRadio] = useState(HOME_PAGE);

  return (
    <div
      className="tabs"
      style={{
        "--navbar-left-element-position": `${a - b}px`,
        "--navbar-right-element-position": `${b - a}px`,
      }}
    >
      <input
        type="radio"
        id="radio-1"
        name="tabs"
        value={HOME_PAGE}
        checked={selectedRadio === HOME_PAGE}
      />
      <label
        ref={(e) => {
          if (e) {
            const ele = e.getBoundingClientRect();
            setA(ele.left);
          }
        }}
        onClick={() => {
          setSelectedRadio(HOME_PAGE);
          dispatch(setHomePageContent(HOME_PAGE));
        }}
        className="tab"
        htmlFor="radio-1"
      >
        <i className="fa-solid fa-house"></i>
        <span className="notification">2</span>
      </label>
      <input
        type="radio"
        id="radio-2"
        name="tabs"
        value={MESSAGE_PAGE}
        checked={selectedRadio === MESSAGE_PAGE}
      />
      <label
        ref={(e) => {
          if (e) {
            const ele = e.getBoundingClientRect();
            setB(ele.left);
          }
        }}
        onClick={() => {
          setSelectedRadio(MESSAGE_PAGE);
          dispatch(setHomePageContent(MESSAGE_PAGE));
        }}
        className="tab"
        htmlFor="radio-2"
      >
        <i className="fa-solid fa-message"></i>
      </label>
      <input
        type="radio"
        id="radio-3"
        name="tabs"
        value={PROVIDE_PAGE}
        onClick={() => {
          setSelectedRadio(PROVIDE_PAGE);
          dispatch(setHomePageContent(PROVIDE_PAGE));
        }}
        checked={selectedRadio === PROVIDE_PAGE}
      />
      <label className="tab" htmlFor="radio-3">
        <i className="fa-solid fa-briefcase"></i>
      </label>
      <span className="glider"></span>
      <span className="underline"></span>
    </div>
  );
};

export default NavBarV2;
