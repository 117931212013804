import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import clsx from "clsx";

// https://codepen.io/hollyb/pen/YRzRgy
const PopoverMenu = ({ position = "top-right", options=[] }) => {
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => {
    setMenuVisible((visible) => !visible);
  };

  useEffect(() => {
    const handleClick = (event) => {

      if (
        menuVisible &&
        !wrapperRef1?.current?.contains(event.target) &&
        !wrapperRef2?.current?.contains(event.target)
      ) {
        toggleMenu();
      }
    };

    document.addEventListener("mousedown", handleClick);

    if (menuVisible === false) {
      document.removeEventListener("mousedown", handleClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [menuVisible]);

  return (
    <div className="popover-menu" id="popover-menu" onClick={toggleMenu}>
      <div className="ellipse" ref={wrapperRef2}>
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </div>
      {menuVisible && (
        <div ref={wrapperRef1} className={clsx("square", position)}>
          <ul>
            {options.map((element) => 
              <li onClick={element.handler}>{element.text}</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopoverMenu;
