import React from "react";
import "./styles.scss";

const Loading = (props) => {
  return (
    <div className="spinner-container">
      <div className="spinner-box">
        <div className="spinner"></div>
        <div className="spinner-message">{props?.message}</div>
      </div>
    </div>
  );
};

export default Loading;
