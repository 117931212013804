import React from "react";
import { useDispatch } from "react-redux";
import "../../../skeleton-modal.scss";
import { closeModal } from "../../../state/slices/globalmodalslice";
import "./styles.scss";
import Button from "../../button";
import DreamerImg from "../../../static/images/dreamer.jpg";
import { setHomePageContent } from "../../../state/slices/homepageslice";
import { SETTINGS_PAGE } from "../../../utils/constants";

// sign up modal
// https://codepen.io/paulmcclean/pen/YGXWQY

const WelcomeModal = () => {
  const dispatch = useDispatch();

  return (
    <div className="modal modal-open">
      <div className="modal-inner">
        <div className="modal-content">
          <div className="modal-content-inner">
            <h4>
              <center>Welcome</center>
            </h4>
            <h6>
              <center>Help Exchange</center>
            </h6>
          </div>
          <div>
            <img src={DreamerImg} alt=""></img>
          </div>
          <h6>
            <center>
              Please complete you profile and update your phone number to use
              the app
            </center>
          </h6>
          <Button
            onClick={() => {
              dispatch(setHomePageContent(SETTINGS_PAGE));
              dispatch(closeModal());
            }}
            title="Update Profile"
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
