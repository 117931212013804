import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../skeleton-modal.scss";
// import { hideSignUpModal } from "../../../state/slices/indexSlice";
// import { saveUserCreds, setLoading } from "../../../state/slices/globalslice";
import { hideAddNewSkillModal } from "../../../state/slices/globalmodalslice";
import "./styles.scss";

const ConfirmAddNewSkillModal = () => {
  const dispatch = useDispatch();
  const skillName = useSelector(
    (state) => state?.modals?.addNewSkillModalData?.skillName
  );

  const selectExistingSkillHandler = () => {
    dispatch(hideAddNewSkillModal());
  };

  return (
    <div className="modal modal-open">
      <div className="modal-inner">
        <div className="modal-content">
          <div className="modal-content-inner">
            <h6> Create a new skill?</h6>
            Skill named "{skillName}" does not exist in our system.!
          </div>

            <div className="add-skill-select-container">
              <div className="update-profile-explain-modal">
                <button
                  className="button button-primary extra-margin"
                >
                  Create new skill
                </button>
                <div className="update-profile-explain-text">
                Select an existing skill if your are unable to find something
                suitable.
                </div>
              </div>

              <div className="or-button">-OR-</div>
              <div className="update-profile-explain-modal">
                <button
                  onClick={selectExistingSkillHandler}
                  className="button button-primary extra-margin"
                >
                  Select existing
                </button>
                <div className="update-profile-explain-text">

                Select an exisiting skill if you want to maximise the
                opportunities
                </div>
              </div>

              {/* <p className='error'> Please enter valid a valid first name and last name</p> */}
            </div>

          <hr className="modal-buttons-seperator" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddNewSkillModal;
