import { getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../config/firebaseConfig";
import { getAuth } from "firebase/auth";

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

connectFunctionsEmulator(functions, "localhost", 5001);
const getNearbyJobsFn = httpsCallable(functions, "getNearbyJobs");

const getJobsInArea = (onComplete, onFailure) => {
  getNearbyJobsFn().then((result) => {
    // Read result of the Cloud Function.
    /** @type {any} */
    onComplete(result.data);
    // const data = result.data
    // const sanitizedMessage = data.text
  });
};

export {getJobsInArea};
