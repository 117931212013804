import React from "react";
import { useField } from "formik";
import clsx from "clsx";
import "./styles.scss";

const CustomField = (props) => {
  const [field, meta, helpers] = useField(props);
  const { error } = meta;

  return (
    <div className="input-field">
      <input
        {...props}
        {...field}
        className={clsx({ "input-bar-error": error })}
      />
      <div className="error">{error}</div>
    </div>
  );
};

export default CustomField;
