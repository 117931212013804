import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../skeleton-modal.scss";
import { displaySignUpModal, hideLogInModal } from "../../../state/slices/globalmodalslice";
import InputBar from "../../inputbar";
import Label from "../../label";
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import Loading from "../../loading";
import { setLoading } from "../../../state/slices/globalslice";

// sign up modal
// https://codepen.io/paulmcclean/pen/YGXWQY

// const signUpWithEmailPassword = () => {
//     const id = document.getElementById('id_w').value;
//     const password = document.getElementById('pass_w').value;

//     createUserWithEmailAndPassword(auth, id, password);
//   }

// useEffect( () =>  {
//     console.log('in use effect')
//     onAuthStateChanged( auth, user => {

//       if (user) {
//         setUser(user);
//         console.log("user exists");
//       } else {
//         setUser(user);
//         console.log("user not logged in");
//       }

//     } )

//   }, [])

const LoginModal = () => {
  const dispatch = useDispatch();
  const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [emailBottomError, setEmailBottomError] = useState('');

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loginError, setLoginError] = useState("");

  //    useState

  // useEffect(()=> {
  // }, [config])

  const handleLogin = async () => {
    let userCreds;

    //validate all the elements
    setEmailError("");
    setPasswordError("");

    // email bar?

    setTimeout(() => {
      if (email.length < 3) {
        setEmailError("Please enter an email");
      } else if (!emailRegEx.test(email)) {
        setEmail("");
        setEmailError("Please enter a valid email");
        // setEmailBottomError(`'${email} is not an valid email address'`);
      } else {
        setEmailError("");
      }

      //password
      if (password.length < 3) {
        setPasswordError("Please enter a password");
      } else {
        setPasswordError("");
      }
    }, 100);

    // send the login request
    // display some spinner
    // const loginEmailPassword = async () => {
    // const firebaseApp = initializeApp();
    const auth = getAuth();
    // connectAuthEmulator(auth, "http://localhost:9099")

    // const id = document.getElementById('id_w').value;
    // const password = document.getElementById('pass_w').value;
    dispatch(setLoading({ state: true }));

    // userCreds = await
    setPersistence(auth, inMemoryPersistence).then(() => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCreds) => {
          dispatch(hideLogInModal());
          dispatch(setLoading({ state: false }));
        })
        .catch((reason) => {
          setLoginError(reason.code);
          dispatch(setLoading({ state: false }));
        });
    });
  };
  const handleSignUp = () => {
    dispatch(hideLogInModal());
    dispatch(displaySignUpModal());
  };

  return (
    <div className="modal modal-open">
      <div className="modal-inner">
        <div className="modal-content">
          <div
            onClick={() => {
              dispatch(hideLogInModal());
              dispatch(displaySignUpModal());
            }}
            className="modal-close-icon"
          >
            <a className="close-modal">
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <div className="modal-content-inner">
            <h4>
              <center>Login</center>
            </h4>
            <h6>
              <center>Login to access Help Exchance</center>
            </h6>
          </div>

          {loginError && <div className="modal-content-error-banner">{loginError}</div>}

          <form>
            <div className="row">
              <div className="twelve columns">
                <Label title="Email" />
                <InputBar
                  type="email"
                  placeholder="Your valid email id."
                  errorMsg={emailError}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  autocomplete="username"
                  testData="sign-up-modal__username"
                />
              </div>

              <div className="twelve columns">
                <Label title="Password" />
                <InputBar
                  type="password"
                  errorMsg={passwordError}
                  placeholder="Your password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  autocomplete="current-password"
                  testData="sign-up-modal__password"
                />
              </div>

              {/* <p className='error'> Please enter valid a valid first name and last name</p> */}
            </div>
          </form>

          <div className="modal-buttons">
            <div className="center">
              {/* <button onClick={handleCancel} className="button">Login</button> */}
              <button
                onClick={handleLogin}
                className="button button-primary extra-margin large-button"
                test-data="sign-up-modal__login-button"
              >
                Login
              </button>
            </div>
          </div>

          <hr className="modal-buttons-seperator" />

          <div className="modal-content-inner">
            {/* <h4><center>Sign Up</center></h4> */}
            <h6>
              <center>Not a member of Help Exchange?</center>{" "}
            </h6>
            <h6>
              <center>Sign up, its free and easy</center>{" "}
            </h6>
          </div>

          <div className="modal-buttons">
            <div className="center">
              {/* <button onClick={handleCancel} className="button">Login</button> */}
              <button onClick={handleSignUp} className="button button-primary extra-margin large-button">
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
