import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../skeleton-modal.scss";
import {
  displaySignUpModal,
  displayWelcomeModal,
  hideSignUpModal,
} from "../../../state/slices/globalmodalslice";
import InputBar from "../../inputbar";
import Label from "../../label";
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import Loading from "../../loading";
import {  setLoading, setFirstLoginData } from "../../../state/slices/globalslice";
import { Field, Form, Formik } from "formik";
import "./styles.scss";
import Button from "../../button";
import * as Yup from "yup";
import CustomField from "../../customfield";

// sign up modal
// https://codepen.io/paulmcclean/pen/YGXWQY

const SignUpModal = () => {
  const dispatch = useDispatch();
  const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi;
  const headerError = useSelector(
    (state) => state?.modals?.signUpModalData?.errors?.headerMessage?.code
  );

  const auth = getAuth();

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().matches(emailRegEx, "Invalid Email").required("Required"),
    firstname: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastname: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ).required("Password is required"),
  });
  

  return (
    <div className="modal modal-open">
      <div className="modal-inner">
        <div className="modal-content">
          <div
            onClick={() => {
              dispatch(hideSignUpModal());
            }}
            className="modal-close-icon"
          >
            <a className="close-modal">
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <div className="modal-content-inner">
            <h4>
              <center>Sign Up</center>
            </h4>
            <h6>
              <center>Help Exchange</center>
            </h6>
          </div>
          {headerError && (
            <div className="modal-content-error-banner">{headerError}</div>
          )}
          <Formik
            initialValues={{
              emailAddress: "",
              firstname: "",
              lastname: "",
              password: "",
              confirmPassword: "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values) => {
              dispatch(
                setLoading({ state: true, message: "Creating your account" })
              );
              createUserWithEmailAndPassword(
                auth,
                values.emailAddress,
                values.password
              )
                .then((userCreds) => {
                  dispatch(setLoading({ state: false }));
                  dispatch(hideSignUpModal());
                  // dispatch(saveUserCreds(userCreds?.user));
                  dispatch(setFirstLoginData({firstname: values.firstname,lastname: values.lastname, firstSignIn: true }))
                  dispatch(displayWelcomeModal());
                })
                .catch((error) => {
                  dispatch(setLoading({ state: false }));
                  dispatch(
                    displaySignUpModal({ errors: { headerMessage: error } })
                  );
                  console.error(error);
                });
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="sign-up-form__container">
                  <label>Email: </label>
                  <CustomField type="text" name="emailAddress" errors={errors} />
                  <label>First Name: </label>
                  <CustomField type="text" name="firstname" errors={errors} />
                  <label>Last Name: </label>
                  <CustomField type="text"  name="lastname" errors={errors} />
                  <label>Password </label>
                  <CustomField type="password" name="password" errors={errors} />
                  <label>Confirm Password </label>
                  <CustomField type="password" name="confirmPassword" errors={errors} />
                </div>
                <div className="sign-up-form__button">
                  <Button title="Sign Up" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
