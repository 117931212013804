import React from "react";

const Button = (props) => {
  const { onClick, title, className, type } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      className={`button button-primary extra-margin large-button ${className}`}
    >
      {title}
    </button>
  );
};

export default Button;
