import React from "react";
import { useDispatch } from "react-redux";
import { getDesc, getIcon, getTitle } from "../settingshomescreen/utils";
import "./styles.scss";
import { handleSettingsButtonClick } from "../../state/slices/globalmodalslice";

const SettingsCard = (props) => {
  const dispatch = useDispatch();
  const { element } = props;
  const title = getTitle(element);
  const description = getDesc(element);
  const image = getIcon(element);

  return (
    <div
      onClick={() => {
        dispatch(handleSettingsButtonClick(element));
      }}
      className="settings-grid-card"
    >
      {image}
      <div className="settings-grid-card__header-text">{title}</div>
      <div className="settings-grid-card__explain-text">{description}</div>
    </div>
  );
};

export default SettingsCard;
