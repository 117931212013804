// https://freefrontend.com/css-tab-bars/
// https://codepen.io/havardob/pen/ExVaELV

import React from "react";
import "./styles.scss";

const SwitchV2 = () => {
  return (
    <div className="job_switch__container">
      <div className="job_type">Find Jobs</div>
      <div className="job_switch__component">
        <input type="radio" id="job_switch-1" name="job_switch" />
        <label className="switch" htmlFor="job_switch-1">
          <i className="fa-solid fa-magnifying-glass left-icon"></i>
        </label>
        <input type="radio" id="job_switch-2" name="job_switch" />
        <label className="switch second-switch" htmlFor="job_switch-2">
          <i className="fa-solid fa-briefcase right-icon"></i>
        </label>
        <span className="glider"></span>
      </div>
      <div className="job_type">Provide Jobs</div>
    </div>
  );
};

export default SwitchV2;
