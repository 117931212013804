import React from "react";
import "../styles.scss";

const CategoryIcon = ({ faIcon, title }) => {
  return (
    <div className="categories__icon">
      <div className="categories__icon-icon">
        <i className={faIcon}></i>
      </div>
      <div className="categories__icon-text">{title}</div>
    </div>
  );
};

const Categories = () => {
  return (
    <div className="categories-container">
      <div className="categories__top-text">Find Help ...</div>
      <div className="categories__icon-container">
        <CategoryIcon title="Cleaning" faIcon="fa-solid fa-spray-can-sparkles"/>
        <CategoryIcon title="Child Care" faIcon="fa-solid fa-child-reaching"/>
        <CategoryIcon title="Aged Care" faIcon="fa-solid fa-person-cane"/>
        <CategoryIcon title="Special Care" faIcon="fa-solid fa-wheelchair-move"/>
        <CategoryIcon title="Pet Care" faIcon="fa-solid fa-dog"/>
      </div>
      <div className="categories__bottom-text">... Find Jobs</div>
    </div>
  );
};

export default Categories;
