import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { getAdvtImageSrcURL } from "../../firestorage";
import { getAuth } from "firebase/auth";
import { displayModal } from "../../state/slices/globalmodalslice";
import { JOB_LISTING_MODAL } from "../../state/modal-constants";
import Button from "../button";
import moment from "moment";
import PopoverMenu from "../popovermenu";

const JobListItemSmall = (props) => {
  const dispatch = useDispatch();

  const details = props.job;
  const [advtImgSrc, setAdvtImgSrc] = useState();

  const onClickHandler = (e) => {
    if (!document.getElementById("ellipse-container").contains(e.target)) {
      dispatch(displayModal({ modal: JOB_LISTING_MODAL, data: details }));
    }
  };

  useEffect(() => {
    const mainImageUUID = JSON.parse(details.imageFileNames).primary;
    if (mainImageUUID) {
      getAdvtImageSrcURL(mainImageUUID, details.storageUUID, (value) => {
        setAdvtImgSrc(value);
      });
    }
  }, [details]);

  return (
    <div className="helpexch-job-item__container" onClick={onClickHandler}>
      <div className="helpexch-job-item__image-container">
        <img className="helpexch-job-item__image" src={advtImgSrc} />
      </div>
      <div className="helpexch-job-item__header-container">
        <div className="helpexch-job-item__header-text">{details.jobTitle}</div>
      </div>
      <div className="helpexch-job-item__duration-container">
        <div className="helpexch-job-duration__icon">
          <i class="fa-regular fa-calendar"></i>
        </div>

        <div className="helpexch-job-duration__text">
          {moment(details.creationDate).fromNow()}
        </div>
      </div>
      <div className="helpexch-job-item__ellipse-container" id="ellipse-container">
        <PopoverMenu
          position="top-right"
          options={[
            {
              text: "Delete",
              handler: () => {
                // deleteChat(metadataId);
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default JobListItemSmall;
