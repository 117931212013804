import React from 'react';
import { CREATE_A_JOB, LIST_OF_JOBS_BREADCRUMB, LIST_POSTED_JOBS } from "./constants";
import { useDispatch } from 'react-redux';
import { displayModal } from '../../../state/slices/globalmodalslice';
import { JOB_POSTING_MODAL } from '../../../state/modal-constants';
import { setProvidePageBreadcrumb } from '../../../state/slices/homepageslice';

export const getTitle = (index) => {
  switch (index) {
    case CREATE_A_JOB:
      return "Create Job";
    case LIST_POSTED_JOBS:
      return "List Jobs";
  }
};

export const getDesc = (index) => {
  switch (index) {
    case CREATE_A_JOB:
      return "Post a new job";
    case LIST_POSTED_JOBS:
      return "List all jobs by you";
  }
};

export const getIcon = (index) => {
  switch (index) {
    case CREATE_A_JOB:
      return <i class="fa-solid fa-briefcase"></i>;
    case LIST_POSTED_JOBS:
      return <i class="fa-solid fa-list-ul"></i>;
  }
};

export const getOnClick = (index) => {
    switch (index) {
        case CREATE_A_JOB:
          return (displayModal({ modal: JOB_POSTING_MODAL }));
        case LIST_POSTED_JOBS:
          return (setProvidePageBreadcrumb(LIST_OF_JOBS_BREADCRUMB));
      }
};
