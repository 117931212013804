import React from "react";
import "./styles.scss";
import {
  getTitle,
  getDesc,
  getIcon,
  getOnClick,
} from "../providehomescreen/utils/utils";
import { useDispatch } from "react-redux";

const LargeCardButton = (props) => {
  const dispatch = useDispatch();
  const { element } = props;
  return (
    <div
      onClick={() => {
        dispatch(getOnClick(element));
      }}
      className="large-grid-card"
    >
      {getIcon(element)}
      <div className="large-grid-card__header-text">{getTitle(element)}</div>
      <div className="large-grid-card__explain-text">{getDesc(element)}</div>
    </div>
  );
};

export default LargeCardButton;
