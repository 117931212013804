import React from "react";
import "./HomeHomeScreenLeftBar.scss";

const HomeHomeScreenLeftBar = () => {
  return (
    <div className="home-home-screen_left-container">
      <div className="home-home-screen_left-container-view-heading">
        <i class="fa-solid fa-briefcase"></i>
        <span> Provider View</span>
      </div>

      <div className="home-home-screen_left-container-heading">
        Interested In:
      </div>

      <div className="home-home-screen_left-container-button">
        <input type="checkbox"></input>
        <label>Cleaning</label>
      </div>
      <div className="home-home-screen_left-container-button">
        <input type="checkbox"></input>
        <label>Child Care</label>
      </div>
      <div className="home-home-screen_left-container-button">
        <input type="checkbox"></input>
        <label>Aged Care</label>
      </div>
      <div className="home-home-screen_left-container-button">
        <input type="checkbox"></input>
        <label>Special Care</label>
      </div>
      <div className="home-home-screen_left-container-button">
        <input type="checkbox"></input>
        <label>Pet Care</label>
      </div>
    </div>
  );
};

export default HomeHomeScreenLeftBar;
