import React from "react";
import clsx from "clsx";

const InputBar = (props) => {
  const {
    name,
    onBlur,
    id,
    type,
    placeholder,
    errorMsg,
    onChange,
    value,
    // bottomError,
    autocomplete = "off",
    className,
    readOnly,
    testData
  } = props;

  return (
    <>
      <input
        name={name}
        className={clsx(
          "u-full-width",
          { "input-bar-error": errorMsg },
          className
        )}
        type={type ? type : "text"}
        placeholder={errorMsg ? errorMsg : placeholder}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autocomplete={autocomplete}
        readOnly={readOnly}
        test-data={testData}
      />
      {/* <p className="error"> {bottomError}</p> */}
    </>
  );
};

export default InputBar;
