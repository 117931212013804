import React from "react";
import GenericModal from "../genericmodal";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { closeMessageModal } from "../../../state/slices/globalmodalslice";

const MessageModal = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.messageModalData?.message);
  return (
    <GenericModal messageModal={true} closeButton={true} closeButtonFn={() => {
      dispatch(closeMessageModal());
    }}>
      {message}
    </GenericModal>
  );
};

export default MessageModal;
