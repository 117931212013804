// import {createStore} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import cacheslice from "./slices/cacheslice";
import chatslice from "./slices/chatslice";

// import indexSlice from "./slices/indexSlice.js.notused";
import globalmodalslice from "./slices/globalmodalslice";
import globalSlice from "./slices/globalslice";
import homepageslice from "./slices/homepageslice";
import userslice from "./slices/userslice";

export const store = configureStore({
    reducer: {
        // index: indexSlice,
        global: globalSlice,
        homepage: homepageslice,
        modals: globalmodalslice,
        user: userslice,
        chat: chatslice,
        cache: cacheslice,
    }
})

export const RootState = store.getState;
export const AppDispatch = store.dispatch;