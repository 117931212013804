// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZrp71XoLdk0lBTDq_qZOuFqm4DXosiqM",
  authDomain: "aksinghs.firebaseapp.com",
  projectId: "aksinghs",
  storageBucket: "aksinghs.appspot.com",
  messagingSenderId: "142191716995",
  appId: "1:142191716995:web:c6f4b87eac2f937cf109b6",
  databaseURL: "https://aksinghs-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

export default firebaseConfig;
