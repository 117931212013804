/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import AdvertisementBar from "./AdvertisementBar";
import Message from "./Message";
import "../styles.scss";
import SendImg from "../../../static/icons/icons8-send-48.png";
import BackImg from "../../../static/icons/circle-left-regular.svg";
import DownIconImg from "../../../static/icons/down-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleBottomNavBarVisibility } from "../../../state/slices/globalslice";
import { CHAT } from "../../../firedb";
import logoImg from "../../../static/images/HE.png";
import { ref, getDatabase } from "firebase/database";
import { useListVals } from "react-firebase-hooks/database";
import { openChatId } from "../../../state/slices/chatslice";

const ChatWithPersonEmptyScreen = () => {
  return (
    <div className="chat-with-person-empty-screen__container">
      <div className="chat-with-person-empty-screen__image-container">
        <img src={logoImg} alt="" />
      </div>
      <div className="chat-with-person-empty-screen__text-container">
        Help Exchange is a platform that helps people who need a job and people
        who need someone to do a job stay connected.
      </div>
    </div>
  );
};
const ChatWithPerson = () => {
  const dispatch = useDispatch();

  const database = getDatabase();

  const openChatIdSelector = useSelector((state) => state.chat.openChatId);

  const [snapshots, loading, error] = useListVals(
    ref(database, `CHAT_RECORD_WITH_METADATA_FOR_CHAT_ID/${openChatIdSelector}/MESSAGES/`)
  );

  useEffect(() => {
    if (loading === false) {
      document
        .getElementById("chatDummyScroll")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [snapshots]);

  const onClickHandler = () => {
    dispatch(openChatId(undefined));
  };

  console.log(snapshots.length);

  return (!openChatIdSelector ? (
    <ChatWithPersonEmptyScreen />
  ) : (
    <>
     <div className="chat-with-person__header">
      <div className="chat-with-person__header__back-button" onClick={onClickHandler}><img src={BackImg}></img></div><AdvertisementBar />
      </div>
      {/* <div>{sharedState.componentState.details.chatMessageRecordPath}</div> */}
      <div className="messages" id="chat">
        {!loading &&
          snapshots &&
          snapshots.map((snapshot) => (
            // <div>{JSON.stringify(snapshot.val())}</div>
            <Message message={snapshot.message} from="you" />
          ))}
        <div id="chatDummyScroll"></div>
      </div>
      <div className="input">
        <img
          className="sendIcon"
          onClick={() => {
            dispatch(toggleBottomNavBarVisibility());
          }}
          src={DownIconImg}
        />

        <input
          id={"msgComposer"}
          placeholder="Type your message here!"
          type="text"
        />
        <img
          className="sendIcon"
          src={SendImg}
          onClick={() => {
            // const state = setOfMsg;
            const message = document.getElementById("msgComposer").value;
            CHAT.sendMessageToChat(
              openChatIdSelector,
              message,
              () => {
                setTimeout(() => {
                  document.getElementById("msgComposer").value = "";
                  document
                    .getElementById("chatDummyScroll")
                    .scrollIntoView({ behavior: "smooth" });
                });
              }
            );
            // CHAT.createNewChat({
            //   person1: { uuid: "4gD7IEx0reYlclbxS90oCT909Jj2" },
            //   person2: { uuid: "vbpGlUTaUAdvbn22u4q9bPhTx0k1" },
            //   advertisementId: "0000000000000",
            // });
            // setSetOfMsg((state) => {
            //   // state.push({
            //   //   message: document.getElementById("msgComposer").value,
            //   //   from: "you",
            //   // });
            //   return [
            //     ...state,
            //     {
            //       message: document.getElementById("msgComposer").value,
            //       from: "you",
            //     },
            //   ];
            // });
          }}
        />
        {/* <i className="fas fa-microphone"></i> */}
      </div>
    </>
  ));
};

export default ChatWithPerson;
