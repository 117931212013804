import React from "react";
import "./App.scss";
import "./constants.scss";
import "./normalize.css";
import "./skeleton.css";
import "./custom.scss";
// import "./reset.css";

import { initializeApp } from "firebase/app";

import NavBar from "./components/navbar";
import FrontPage from "./components/frontpage";
import { useSelector } from "react-redux";
import Loading from "./components/loading";
import Homepage from "./components/homepage";
import NavBarV2 from "./components/navbarv2";
import useMediaQuery, { MOBILE, TABLET } from "./hooks/media/useMediaQuery";
import { getAuth } from "firebase/auth";
import firebaseConfig from "./config/firebaseConfig";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// onAuthStateChanged(auth, user => {
//   if (user != null) {
//     console.log("logged in");
//   } else {
//     console.log("no user");
//   }
// });

const monitorAuthState = async () => {};

const App = () => {
  // const [user, setUser] = useState();
  // const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.global.isLoading);
  const user = useSelector((state) => state.global.user);
  const bottomNavBarVisible = useSelector((state) => state.global.bottomNavBarVisible);
  const device = useMediaQuery();
  console.log("device:" + device);

  // useEffect(() => {
    // const firebaseApp = initializeApp(firebaseConfig);

    // const auth = getAuth(firebaseApp)

    // dispatch(saveConfig(firebaseConfig));

    // Initialize Firebase
    //const app = initializeApp(firebaseConfig);
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

  // })

  return (
    <>
      {isLoading?.state && <Loading message={isLoading.message} />}
      <NavBar loginIcon={auth.currentUser ? false : true} />
      {auth?.currentUser ? <Homepage /> : <FrontPage />}
      {bottomNavBarVisible && auth?.currentUser && [MOBILE, TABLET].includes(device) && (
        <div className="bottom-nav-bar">
          <NavBarV2 />
        </div>
      )}
    </>

    // {signUpModalVisible && <SignUpModal />}
    // <NavBar loginIcon={true} user={user}/>
    // <label for="exampleEmailInput">Creds</label>
    // <Animation />
    // <div className='row'>
    // <input className="u-full-width" type="text" placeholder="ID" id="id_w" />
    // </div>
    // <input className="u-full-width" type="text" placeholder="Password" id="pass_w" />
    /* <input onClick={loginEmailPassword} className="button-primary u-full-width" type="submit" value="Submit"></input>
    <input onClick={signUpWithEmailPassword} className="button-primary u-full-width" type="submit" value="Sign In"></input>
    <input onClick={() => {
      signOut(auth);
    }} className="button-primary u-full-width" type="submit" value="Sign In"></input> */
  );
};

export default App;
