import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { isEqual, unionWith } from "lodash-es";


//https://stackoverflow.com/questions/71013399/react-firebase-read-data-results-in-too-many-renders
const useChatList = () => {
  const db = getDatabase();
  const auth = getAuth();
  const userId = auth.currentUser.uid;

  const [chatList, setChatList] = useState([]);

  useEffect(() => {
    onValue(ref(db, `CHAT_LIST_ITEMS_FOR_USER/${userId}/CHAT_LIST/`), (snapshot) => {
      console.log("snapshot recieved");

      if (snapshot.exists()) {
        console.log("snapshot recieved: " + JSON.stringify(snapshot.val()));
        const recieved = Object.values(snapshot.val());
        const union = unionWith(chatList, recieved, isEqual);
        setChatList(union);
      } else {
        setChatList([]);
      }
    });
  }, []);

  return chatList;
};

export default useChatList;
