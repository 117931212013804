import React, { useEffect, useState } from "react";
import "../styles.scss";
import {
  getAdvtImageSrcURL,
  getProfileImageSrcURL,
} from "../../../firestorage";
import clsx from "clsx";
import PopoverMenu from "../../popovermenu";
import { deleteChat } from "../../../firecloudfn/onRequest";
import { getAuth } from "firebase/auth";
import useUserDetails from "../../../hooks/cache/useUserDetails";
import { useSelector } from "react-redux";
import useUserProfileImageURL from "../../../hooks/cache/useUserProfileImageURL";
import useJobImageURL from "../../../hooks/cache/useJobImageURL";

const ChatListItem = ({ chat, onClickHandler }) => {
  const auth = getAuth();

  const { person1, person2, jobUUID, metadataId } = chat;

  // const [profileImageURL, setProfileImageURL] = useState(undefined);
  // const [advertisementImageURL, setAdvertisementImageURL] = useState(undefined);

  const profileImageUrl = useUserProfileImageURL(person2);

  const [chatImgAnimation, setChatImageAnimation] = useState(true);
  const [adImgAnimation, setAdImageAnimation] = useState(true);
  const person2details = useUserDetails(person2);
  const jobImageURL = useJobImageURL(jobUUID);

  const getPerson2Name = () => {
   return `${person2details?.firstname} ${person2details?.lastname}`;
  };

  // useEffect(() => {
  //   if (person2.uuid === auth.currentUser.uid) {
  //     getProfileImageSrcURL(person2.uuid, (url) => {
  //       setProfileImageURL(url);
  //     });
  //   } else {
  //     return person1.details?.firstname + " " +person1.details?.lastname;
  //   }
  //   if (person2.uuid) {
  //     getProfileImageSrcURL(person2.uuid, (url) => {
  //       setProfileImageURL(url);
  //     });
  //   }
  // }, []);

  

  // useEffect(() => {
  //   if (job.jobDetails.storageUUID && job.jobDetails.imageFileNames) {
  //     const img = JSON.parse(job.jobDetails.imageFileNames);

  //     getAdvtImageSrcURL(img.primary, job.jobDetails.storageUUID, (url) => {
  //       setAdvertisementImageURL(url);
  //     });
  //   }
  // }, []);

  return (
    <div className="contact slide-to-visible-element" >
      <div className="clickable-container" onClick={onClickHandler}>
      <div className="image-container">
        <div className="chat-image__container">
          <div className="badge">14</div>
          <img
            onLoad={() => {
              setChatImageAnimation(false);
            }}
            onError={() => {
              setChatImageAnimation(false);
            }}
            src={profileImageUrl}
            className={clsx("chat-image__image", {
              "chat-image__image-onload": chatImgAnimation,
            })}
            alt=""
          />
          {/* {person1.uuid} */}
        </div>
      </div>
      <div className="message-text__container">
        <div className="name">{getPerson2Name()}</div>
        <div className="message">That is America's ass 🇺🇸🍑</div>
        {/* {person2.uuid} */}
      </div>
      <div className="image-container">
        <div className="ad-image__container">
          <img
            src={jobImageURL}
            onLoad={() => {
              setAdImageAnimation(false);
            }}
            onError={() => {
              setAdImageAnimation(false);
            }}
            className={clsx("ad-image__image", {
              "ad-image__image-onload": adImgAnimation,
            })}
            alt=""
          />
          {/* {advertisementId} */}
        </div>
      </div>
      </div>

      <div className="settings-button__container">
        <PopoverMenu
          position="top-right"
          options={[
            {
              text: "Delete",
              handler: () => {
                deleteChat(metadataId);
              },
            },
            {
              text: "Report",
              handler: () => {
                console.log("reporting chat");
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ChatListItem;
