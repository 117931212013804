import {
  child,
  get,
  getDatabase,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import _ from "lodash";
import { store } from "../state/store";
import {
  addArrayObjectToList,
  createNewObject,
  getObjectAtPath,
} from "./common";
import { AREA_JOBLIST, JOBS_POSTED, POSTED_JOBLIST, USERS } from "./constants";
import { getAuth } from "firebase/auth";

// export const writeUserData = ({
//   firstname,
//   lastname,
//   address,
//   suburb,
//   userState,
//   pinCode,
// }) => {
//   const db = getDatabase();
//   const state = store.getState();

//   try {
//     const userId = state?.global?.user?.uid;
//     if (userId) {
//       set(ref(db, "users/" + userId + "/profile/"), {
//         firstname,
//         lastname,
//         address,
//         suburb,
//         userState,
//         pinCode,
//       });
//     } else {
//       throw new Error("No uid");
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };

export const getUserData = async () => {
  const db = getDatabase();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  try {

    if (userId) {
      return await get(child(ref(db), "users/" + userId + "/profile/"));
    }
  } catch (error) {
    console.error(error);
  }

  // return returnValue;
};

export const USER = {
  getUserData: (onCompleteHandler, onFailureHandler) => {
    const auth = getAuth();
    const userId = auth.currentUser.uid;

    getObjectAtPath(
      `USERS/${userId}/USER_DETAILS`,
      onCompleteHandler,
      onFailureHandler
    );

  },
};

export const addJobProfile = (jobTitle) => {
  const db = getDatabase();
  const state = store.getState();

  try {
    const userId = state?.global?.user?.uid;

    // create a new child

    const newJobChild = push(child(ref(db), "jobList")).key;

    const updates = {};
    updates["/jobList/jobId" + newJobChild] = {
      job: jobTitle,
      createdBy: userId,
      creationTime: new Date().toISOString(),
    };

    if (userId) {
      update(ref(db), updates);
    } else {
      throw new Error("No uid");
    }
  } catch (e) {
    console.error(e);
  }
};

// add job skill to a user
export const addUserSkillList = (jobTitle) => {
  const db = getDatabase();
  const state = store.getState();

  try {
    const userId = state?.global?.user?.uid;
    if (userId) {
      // const newJobChild = push(child(ref(db), 'jobList')).key;

      // const updates = {};
      // updates['/users/'+userId+'/skillList/'+jobTitle] = {};

      // if (userId) {
      //   update(ref(db), updates);
      // } else {
      //   throw new Error("No uid");
      // }

      const newJobTitleRef = ref(db, `users/${userId}/skillList`);
      const newJobTitleChild = push(newJobTitleRef, jobTitle);

      // set(newJobTitleChild, jobTitle)
      // .catch(error => console.error(error));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserSkillList = async () => {
  const db = getDatabase();
  const state = store.getState();
  // let returnValue;
  try {
    const userId = state?.global?.user?.uid;
    if (userId) {
      return await get(child(ref(db), `users/${userId}/skillList`));
    }
  } catch (error) {
    console.error(error);
  }

  // return returnValue;
};

export const findUsersWithSkills = async ({ pincode, skillname }) => {
  const db = getDatabase();

  return await get(child(ref(db), `skillExplorer/${pincode}/${skillname}`));
};

export const createNewJob = (job, onCompleteHandler, onFailureHandler) => {
  const state = store.getState();

  const auth = getAuth();
  const pinCode = "3978";

  const job0 = {
    ...job,
    postedByUser: auth.currentUser.uid,
    creationTime: new Date().toISOString(),
  };

  addArrayObjectToList(POSTED_JOBLIST, job0, (path) => {
    addArrayObjectToList(
      `${AREA_JOBLIST}/${pinCode}/`,
      { job: path._path.toString() },
      onCompleteHandler,
      onFailureHandler
    );
    addArrayObjectToList(`${USERS}/${userId}/${JOBS_POSTED}`, {
      job: path._path.toString(),
    });
  });
};

// export const createNewJob = (job, onCompleteHandler, onFailureHandler) => {
//   const { jobTitle, jobDesc, paymentType, user } = job;
//   const { pinCode } = user;
//   let paymentAmount, paymentPerTime;

//   if (paymentType === "FixedPayment") {
//     paymentAmount = job.fixedPaymentAmount;
//     paymentPerTime = job.fixedPaymentPerTime;
//   } else if (paymentType === "NegotiablePayment") {
//     paymentAmount = "undefined";
//     paymentPerTime = "undefined";
//   } else if (paymentType === "DoNotMentionPayment") {
//     paymentAmount = "undefined";
//     paymentPerTime = "undefined";
//   }
//   const db = getDatabase();
//   const state = store.getState();

//   try {
//     const userId = state?.global?.user?.uid;

//     // create a new child

//     const newJobChild = push(child(ref(db), "jobList")).key;

//     if (userId && pinCode) {
//       const updates = {};
//       updates[`/areaJobList/${pinCode}/jobId${newJobChild}`] = {
//         ...{
//           jobTitle,
//           jobDesc,
//           user,
//           payment: { paymentAmount, paymentPerTime, paymentType },
//         },
//         creationTime: new Date().toISOString(),
//       };

//       update(ref(db), updates)
//         .then((paramIfAny) => {
//           //success
//           if (_.isFunction(onCompleteHandler)) {
//             onCompleteHandler();
//           }
//         })
//         .catch((error) => {
//           if (_.isFunction(onFailureHandler)) {
//             onFailureHandler();
//           }
//         });
//     } else {
//       throw new Error("No uid");
//     }
//   } catch (e) {
//     console.error(e);
//     if (_.isFunction(onFailureHandler)) {
//       onFailureHandler();
//     }
//   }
// };

// depricated
// export const getJobsInArea = async (job) => {
//   const { pinCode } = job;
//   const db = getDatabase();
//   const state = store.getState();

//   try {
//     const userId = state?.global?.user?.uid;
//     if (userId) {
//       return await get(child(ref(db), `/areaJobList/${pinCode}`));
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

export const CHAT = {
  // findUserChats (returns the list of chats a user has)

  // send message in chat

  // create a new chat

  createNewChat: async (
    { person1, person2, jobDetails },
    onCompleteHandler,
    onFailureHandler
  ) => {
    // https://firebase.google.com/docs/database/web/lists-of-data#append_to_a_list_of_data
    const db = getDatabase();
    // const state = store.getState();
    if (!person1) {
      person1 = {};
      person1.uuid = getAuth().currentUser.uid;
    } else {
      throw new Error("you have to be the first person");
    }

    if (person1.uuid === person2.uuid) {
      throw new Error("how can you chat with yourself");
    } else {
    }

    // what all we need?
    // {
    // 1) UserID Person1
    // 2) UserID Person2
    // 3) Chat message store ID = will get this from step 1
    // 4) Advertisement ID
    // }

    // STEP 1) CREATE A RECORD IN CHAT MESSAGE STORE
    const chatMessageStoreRef = ref(db, "CHAT_MESSAGE_STORE");
    const chatMessagePath = push(chatMessageStoreRef);
    set(chatMessagePath, {})
      .then(() => {
        // first meta data
        // chatMessagePath._path.toString(),

        const chatMessageStoreMetadataRef = ref(
          db,
          chatMessagePath._path.toString()
        );
        const chatMessageStoreMetadataPath = push(chatMessageStoreMetadataRef);

        set(chatMessageStoreMetadataPath, {
          type: "metadata",
          subtype: "chat_created",
          creationTime: new Date().toISOString(),
        });

        //success
        // const chatMessagePathStr = ;

        // STEP 2) CREATE CHAT METADATA
        const chatMetaDataRef = ref(db, "CHAT_METADATA");
        const chatMetaDataPath = push(chatMetaDataRef);
        set(chatMetaDataPath, {
          type: "metadata",
          subtype: "chat_created",
          person1: person1,
          person2: person2,
          jobDetails: jobDetails,
          chatMessageRecordPath: chatMessagePath._path.toString(),
          creationTime: new Date().toISOString(),
        }).then(async () => {
          // STEP 3) ADD TO LIST RECORD FOR BOTH USERS

          // GET BOTH USER'S INFO OBJECT

          const listOfChatsRef1 = ref(
            db,
            `users/${person1.uuid}/list_of_chats/`
          );
          const listOfChatsPath1 = push(listOfChatsRef1);

          set(listOfChatsPath1, {
            chat_metadata: chatMetaDataPath._path.toString(),
          });

          const listOfChatsRef2 = ref(
            db,
            `users/${person2.uuid}/list_of_chats/`
          );
          const listOfChatsPath2 = push(listOfChatsRef2);

          set(listOfChatsPath2, {
            chat_metadata: chatMetaDataPath._path.toString(),
          });
        });

        if (_.isFunction(onCompleteHandler)) {
          onCompleteHandler();
        }
      })
      .catch((error) => {
        if (_.isFunction(onFailureHandler)) {
          onFailureHandler();
        } else throw error;
      });
  },

  getAllUserChats: async () => {
    const db = getDatabase();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    // let returnValue;
    let listOfChats, listOfPromises;
    // step 1 - get list of chats
    try {
      if (userId) {
        const response = await get(
          child(ref(db), "users/" + userId + "/list_of_chats/")
        );
        listOfChats = Object.values(response.val());
      }
    } catch (error) {
      console.error(error);
    }

    if (userId && _.isArray(listOfChats)) {
      listOfPromises = listOfChats.map((chatReference) =>
        get(child(ref(db), chatReference.chat_metadata)).then((result) => {
          return _.clone(result);
        })
      );

      return Promise.all(listOfPromises);
    } else {
      Promise.reject("some error");
    }

    // console.log(details);

    // step 2 - get list of metadata
  },

  sendMessageToChat: (
    chatMessageRecord,
    message,
    onSuccessHandler,
    onFailureHandler
  ) => {
    const db = getDatabase();

    const auth = getAuth();
    const userId = auth.currentUser.uid;
    if (chatMessageRecord && message && userId) {
      const chatMessageRecordRef = ref(db, `CHAT_RECORD_WITH_METADATA_FOR_CHAT_ID/${chatMessageRecord}/MESSAGES/`);
      const chatMessageRecordPath = push(chatMessageRecordRef, {
        type: "message",
        message: message,
        from: userId,
        creationTime: new Date().toISOString(),
      })
        .then(
          (reference) => {
            //on fulfilled
            onSuccessHandler();
          },
          () => {
            //on rejected
          }
        )
        .catch((error) => {
          console.error(error);
          onFailureHandler && onFailureHandler();
          //on rejected
        });
    }

    // set(chatMessageRecordPath, ).then(() => {
    //   onSuccessHandler();
    // });
  },
};

export const JOB = {
  getJob: (path, onCompleteHandler, onFailureHandler) => {
    getObjectAtPath(path, onCompleteHandler, onFailureHandler);
  },
};
