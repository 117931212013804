import React, { useState } from "react";
import logoImg from "../../static/images/HE.png";
import burger from "../../static/icons/bars-solid.svg";

import "./styles.scss";
import "../../animate.css";
import NavBarLoginIcon from "./components/navbarloginicon";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavBarOpen } from "../../state/slices/globalslice";
import NavBarV2 from "../navbarv2";
import useMediaQuery, { DESKTOP } from "../../hooks/media/useMediaQuery";
import { getAuth } from "firebase/auth";

const NavBar = (props) => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const device = useMediaQuery();

  // const [trayOpen, setTrayOpen] = useState(false);
  const navBarOpen = useSelector((state) => state.global.navBarOpen);

  const openNavBar = () => {
    // setTrayOpen(!trayOpen);
    dispatch(toggleNavBarOpen());
    // setTimeout(() => )
  };

  return (
    <>
      <div className="navbar-mobile">
        <div className="left">
          <div className="nav-bar-element">
            <img className="navbar-mobile-logo" src={logoImg}></img>
            <div className="navbar-mobile-title">
              <div className="navbar-mobile-title__topline">Help</div>
              <div className="navbar-mobile-title__bottomline">Exchange</div>
            </div>
          </div>
        </div>

        {[DESKTOP].includes(device) && (auth.currentUser?.uid)  && (
          <div className="center">
            <NavBarV2 />
          </div>
        )}
        <div className="right">
          {props?.loginIcon ? (
            <NavBarLoginIcon />
          ) : (
            <div className="nav-bar-element" onClick={openNavBar}>
              <img
                style={{
                  transform: navBarOpen ? `rotate(90deg)` : `rotate(0deg)`,
                }}
                className="burger-image transition-all"
                src={burger}
              ></img>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;
