import React from "react";
import { useDispatch } from "react-redux";
import { displayLogInModal } from "../../../../state/slices/globalmodalslice";

import LoginIconImg from "../../../../static/icons/loginicon.svg";
import "./styles.css";

const NavBarLoginIcon = () => {
  const dispatch = useDispatch();
  const loginHandler = () => {
    dispatch(displayLogInModal());
  };

  return (
    <div className="nav-bar-element">
      <div className="login-icon-container" test-data="login-profile-icon" onClick={loginHandler}>
        <img className="login-icon-image" src={LoginIconImg} />
        <div className="login-icon-text">Login</div>
      </div>
    </div>
  );
};

export default NavBarLoginIcon;
