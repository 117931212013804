import {createSlice} from "@reduxjs/toolkit";
import { HOME_PAGE } from "../../utils/constants";
import { PROVIDE_HOME } from "../../components/providehomescreen/utils/constants";

const initialState =
{   
    homePageContent: HOME_PAGE,
    providePageBreadcrumb: PROVIDE_HOME,
};

const homePageSlice = createSlice({
    name: "homepage",
    initialState,
    reducers: {
        setHomePageContent: (state, actions) => {
            state.homePageContent = actions.payload;
        },
        setProvidePageBreadcrumb: (state, actions) => {
            state.providePageBreadcrumb = actions.payload;
        }
    }
});

export const {setHomePageContent, setProvidePageBreadcrumb} = homePageSlice.actions;

export default homePageSlice.reducer;