import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../firedb";
import { setUser } from "../../state/slices/userslice";
import {
  FIND_PAGE,
  HOME_PAGE,
  MESSAGE_PAGE,
  PROVIDE_PAGE,
  SETTINGS_PAGE,
} from "../../utils/constants";
import ConfirmAddNewSkillModal from "../modals/confirmaddnewskillmodal";
import JobListingModal from "../modals/joblistingmodal";
import FindHomeScreen from "../findhomescreen";
import HomeHomeScreen from "../homehomescreen";
import ProvideHomeScreen from "../providehomescreen";
import SettingsHomeScreen from "../settingshomescreen";
import "./styles.scss";
import MessageHomeScreen from "../messagehomescreen";
import WelcomeModal from "../modals/welcomemodal";
import {
  JOB_LISTING_MODAL,
  JOB_POSTING_MODAL,
  UPDATE_EMAIL_MODAL,
  UPDATE_PERSONAL_DETAILS_MODAL,
  UPDATE_PHONE_MODAL,
  UPLOAD_PROFILE_IMAGE_MODAL,
  WELCOME_MODAL,
} from "../../state/modal-constants";
import NavBarFullPage from "../navbar/components/NavBarFullPage";
import { getJobsInArea } from "../../firecloudfn/onCall";
// import JobListingModalV2_lazy from "../modals/joblistingformV2_modal_lazy";
import { lazy } from "react";
import Loading from "../loading";
import NotificationBar from "../notificationbar";
import { getAuth } from "firebase/auth";
import { updateNotificationBar } from "../../state/slices/globalslice";
import BottomBarWithLove from "../bottombarwithlove";
import MessageModal from "../modals/messagemodal";

const UpdatePhoneModal_lazy = lazy(() =>
import("../modals/updatephonemodal_lazy")
);
const UpdateEmailModal_lazy = lazy(() =>
import("../modals/updateemailmodal_lazy")
);
const JobListingModalV2_lazy = lazy(() =>
  import("../modals/jobpostingformV2_modal_lazy")
);
const UpdatePersonalDetailsModal_lazy = lazy(() => 
  import("../modals/updatepersonaldetailsmodal_lazy")
);

const UploadProfileImageModal_lazy = lazy(() => 
  import("../modals/uploadprofileimagemodal_lazy")
);


const Homepage = () => {
  const dispatch = useDispatch();
  // let homeScreen = <FindHomeScreen />;
  const skillModalVisible = useSelector(
    (state) => state.modals.addNewSkillModal
  );

  const visibleModal = useSelector((state) => state.modals.visibleModal);
  const messageModal = useSelector((state) => state.modals.messageModal);
  
  const homePageContent = useSelector(
    (state) => state.homepage.homePageContent
  );
  const notificationBar = useSelector((state) => state.global.notificationBar);



  useEffect(() => {
    // fetch whatever details we need from the DB

    // 1) User details
    const fetchUserData = async () => {
      const userData = await getUserData();
      const userDataVal = userData.val();

      if (userDataVal) {
        dispatch(setUser(userDataVal));
      }
    };
    fetchUserData();
  }, [dispatch]);

  useEffect(() => {
    const auth = getAuth();

    if (!auth.currentUser.phoneNumber) {
      dispatch(
        updateNotificationBar({
          state: true,
          data: { message: "Please update your profile to start using HelpExch." },
        })
      );
    }
  }, []);

  const getHomePageContent = () => {
    switch (homePageContent) {
      case HOME_PAGE:
        return <HomeHomeScreen />;

      case FIND_PAGE:
        return <FindHomeScreen />;
      // computeTransition(homePageContent);
      // setTimeout(()=> {
      //   dispatch(setHomeScreenState(homeScreen))
      // }, 100)
      // break;

      case PROVIDE_PAGE:
        return <ProvideHomeScreen />;
      // computeTransition(homePageContent);
      // setTimeout(()=> {
      //   dispatch(setHomeScreenState(homeScreen))
      // }, 100)
      // break;

      case MESSAGE_PAGE:
        return <MessageHomeScreen />;

      case SETTINGS_PAGE:
        return <SettingsHomeScreen />;
      // computeTransition(homePageContent);
      // setTimeout(()=> {
      //   dispatch(setHomeScreenState(homeScreen))
      // }, 100)
      // break;?

      default:
    }
  };

  return (
    <>
        <NavBarFullPage />
      {notificationBar?.state && (
        <NotificationBar message={notificationBar?.data?.message} />
      )}
      <div className="homepage__container">
        <Suspense fallback={<Loading />}>
          {messageModal && <MessageModal />}
          {visibleModal === WELCOME_MODAL && <WelcomeModal />}
          {visibleModal === JOB_POSTING_MODAL && <JobListingModalV2_lazy />}
          {visibleModal === UPDATE_PERSONAL_DETAILS_MODAL && <UpdatePersonalDetailsModal_lazy />}
          {visibleModal === UPLOAD_PROFILE_IMAGE_MODAL && <UploadProfileImageModal_lazy />}

          {/* <GenericModal title="Hello" header="Hello world .. !" closeButton={true}/> */}
          {skillModalVisible && <ConfirmAddNewSkillModal />}
          {/* {visibleModal === UPDATE_PERSONAL_DETAILS_MODAL && <ConfirmAddNewSkillModal />} */}
          {visibleModal === UPDATE_PHONE_MODAL && <UpdatePhoneModal_lazy />}
          {visibleModal === UPDATE_EMAIL_MODAL && <UpdateEmailModal_lazy />}
          {visibleModal === JOB_LISTING_MODAL && <JobListingModal />}
        </Suspense>
        {getHomePageContent()}
        <BottomBarWithLove />
      </div>
    </>
  );
};

export default Homepage;
