// https://freefrontend.com/css-chats/
// https://codepen.io/mubangadv/pen/rXrOQa
import React from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "../../hooks/media/useMediaQuery";

import ChatList from "./components/ChatList";
import ChatWithPerson from "./components/ChatWithPerson";

const Chat = () => {
  const openChatId = useSelector((state) => state.chat.openChatId);

  const media = useMediaQuery();
  return ["MOBILE", "TABLET"].includes(media) ? (
    <div className="chat small-screen">
      {openChatId ? <ChatWithPerson /> : <ChatList />}
    </div>
  ) : (
    <div className="large-screen">
      <div className="left chat">
        <ChatList />
      </div>
      <div className="right chat">
        <ChatWithPerson />
      </div>
    </div>
  );
};

export default Chat;
