import {
  child,
  get,
  getDatabase,
  push,
  ref,
  set,
  update,
} from "firebase/database";
import _ from "lodash";
import { store } from "../state/store";
import { AREA_JOBLIST } from "./constants";

export const addArrayObjectToList = (
  dbPath,
  obj,
  onSuccessHandler,
  onFailureHandler
) => {
  const db = getDatabase();

  const _ref = ref(db, dbPath);
  const _path = push(_ref);
  set(_path, obj)
    .then(() => {
      onSuccessHandler && onSuccessHandler(_path);
    })
    .catch((error) => {
      onFailureHandler ? onFailureHandler() : console.error(error);
    });
};

export const getArrayAtPath = (path, onSuccessHandler, onFailureHandler) => {
  const db = getDatabase();
  get(child(ref(db), path))
    .then((value) => {
      onSuccessHandler(value);
    })
    .catch((error) => {
      onFailureHandler(error);
    });
};

export const getObjectAtPath = (path, onSuccessHandler, onFailureHandler) => {
  const db = getDatabase();
  get(child(ref(db), path))
    .then((value) => {
      onSuccessHandler(value);
    })
    .catch((error) => {
      onFailureHandler(error);
    });
};
