import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import "yet-another-react-lightbox/styles.css";
import NoImage from "../../static/icons/no-image-available-icon.jpg";
import "./styles.scss";

// https://github.com/igordanchenko/yet-another-react-lightbox

const ImageSlider = (props) => {
  
  const { slides, loading } = props;
  
  const _slides = slides ? slides: loading ? [] : NoImage; 
    

  return (
    <div className="image-slider__container">
      {loading && <div className="spinner-for-image-slider"></div>}
      <Lightbox slides={_slides} plugins={[Inline]} />
    </div>
  );

};

export default ImageSlider;
