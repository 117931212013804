import React from "react";
import "./styles.scss";
import AustralianFlag from "../../../../static/icons/australia.svg";

const FrontPageLocator = () => {
    return (
        <div className="locator-container">
            <div className="locate-me-input-container">

                <img src={AustralianFlag} className="locate-me-input locate-me-input-image"></img>
                <input className="locate-me-input locate-me-input-input" placeholder="Enter Pincode"></input>
            </div>
            <p className="or-text">OR</p>
            <input className="locate-me-button" type="button" value="Locate Me"></input>

        </div>
    );
};

export default FrontPageLocator;