import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, toggleNavBarOpen } from '../../../state/slices/globalslice'
import { setHomePageContent } from '../../../state/slices/homepageslice'

import SwitchV2 from '../../switchV2'
import { HOME_PAGE, SETTINGS_PAGE } from '../../../utils/constants'
import { getAuth, signOut } from 'firebase/auth'

const NavBarFullPage = () => {
  const dispatch = useDispatch()
  const navBarOpen = useSelector((state) => state.global.navBarOpen)

  return (
    // <div className="nav-bar__container">
    <>
      <div
        className={clsx({
          'nav-bar-slider__backdrop': true,
          open: navBarOpen,
          close: !navBarOpen,
        })}
        onClick={() => {
          dispatch(toggleNavBarOpen())
        }}
      ></div>

      <div
        className={clsx({
          'mobile-menu-container': true,
          open: navBarOpen,
          close: !navBarOpen,
        })}
      >
        <div className="mobile-menu-item">
          <div className="switch_element">
            <SwitchV2 />
          </div>
        </div>
        <div className="mobile-menu-item hover-item" onClick={() => {dispatch(setHomePageContent(SETTINGS_PAGE))}}> Settings</div>
        <div className="mobile-menu-item hover-item" onClick={() => {
          const auth = getAuth();
          dispatch(setLoading({state: true}));

          signOut(auth).then(() => {
          dispatch(setLoading({state: false}));

          }).catch((error) => {
            dispatch(setLoading({state: false}));
          });
        }}>Log Out</div>
        <div className="mobile-menu-item hover-item">Item one</div>
      </div>
    </>
    // </div>
  )
}

export default NavBarFullPage
