import React, { useEffect } from "react";
import "./styles.scss";
import {
  LOGIN_SECURITY,
  MY_LISTINGS,
  PROFILE_INFO,
  ROOT_PAGE,
  SETTINGS,
} from "./utils/constants";
import { useDispatch, useSelector } from "react-redux";
// import PersonalDetailsForm from "./components/personaldetailsform";
import ProvideDetailsForm from "./components/providedetailsform";
// import { setSettingsPageIndex } from "../../state/slices/indexSlice";
// import LoginAndSecurityForm from "./components/loginsecurityform";
import SettingsCard from "../settingscard/SettingsCard";

// const getScreen = (settingsIndex) => {
//   switch (settingsIndex) {
//     case PROFILE_INFO:
//       return <PersonalDetailsForm />;
//     case LOGIN_SECURITY:
//       return <LoginAndSecurityForm />;
//     case MY_LISTINGS:
//       return <ProvideDetailsForm />;

//     case ROOT_PAGE:
//     default:
//       return (
//         <>
//           <div className="generic-container">
//            <h5>Settings</h5>
//             <div className="settings-grid">
//               {SETTINGS.map((element) => (
//                 <SettingsCard element={element} />
//               ))}
//             </div>
//           </div>
//         </>
//       );
//   }
// };

const SettingsHomeScreen = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(setSettingsPageIndex(ROOT_PAGE));
  }, []);
  const { style } = props;
  // const settingsIndex = useSelector((state) => state.index.settingsPage);
  return (
    <div style={style} className="settings-home-screen-container">
      <div className="generic-container">
        <h5>Settings</h5>
        <div className="settings-grid">
          {SETTINGS.map((element) => (
            <SettingsCard element={element} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsHomeScreen;
