import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { saveUserDetails } from "../../state/slices/cacheslice";
import { useDispatch, useSelector } from "react-redux";

//https://stackoverflow.com/questions/71013399/react-firebase-read-data-results-in-too-many-renders
const useUserDetails = (uid) => {
  const db = getDatabase();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.cache.users?.[uid]);

  useEffect(() => {
    // onValue(ref(db, `PUBLIC_DETAILS_FOR_USER/${uid}/`), (snapshot) => {
    onValue(ref(db, `USERS/${uid}/USER_DETAILS`), (snapshot) => {
      console.log("snapshot recieved" + `USERS/${uid}/USER_DETAILS`) ;
      const exists = snapshot.exists();
      const data = snapshot.val();
      if (exists && data) {
        console.log("snapshot recieved and saving to redux: " + JSON.stringify(snapshot.val()));
        
        dispatch(saveUserDetails({uid, data}));

      } else {
        console.error("user details does not exist");
      }
    });
  }, []);

  return userDetails;
};

export default useUserDetails;
