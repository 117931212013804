import React from "react";
import Animation from "../animation";
import FrontPageLocator from "./components/frontpagelocator";
import "./styles.scss";
import LoginModal from "../modals/loginmodal";
import { useSelector } from "react-redux";
import SignUpModal from "../modals/signupmodal";
import {
  LOGIN_MODAL,
  SIGN_UP_MODAL,
} from "../../state/modal-constants";
import { Suspense } from "react";
import Loading from "../loading";
import Categories from "./components/Categories";

const FrontPage = () => {
  const visibleModal = useSelector((state) => state.modals.visibleModal);

  console.log("hello:" + process.env.REACT_APP_USE_FUNCTIONS);

  return (
    <div className="frontpage__container">
      <Suspense fallback={<Loading />}>
        {visibleModal === LOGIN_MODAL && <LoginModal />}
        {visibleModal === SIGN_UP_MODAL && <SignUpModal />}
      </Suspense>
      <div className="app-statement-container">
        <div className="animation-statement-container">
          <Animation />
          <div className="app-statement-text">
            Find People With Specific Skills Or Offer Help To People Nearby And
            Earn Money
          </div>
        </div>
      </div>
      <FrontPageLocator />
      <Categories />
    </div>
  );
};

export default FrontPage;
