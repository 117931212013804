import React from "react";
import "./styles.scss";

const NotificationBar = (props) => {
  const { message } = props;

  return <div className="notification-bar__container">{message}</div>;
};

export default NotificationBar;
