import React, { useEffect, useState } from "react";

import "./animation.scss";

const Animation = () => {
  const [keywordCount, setKeywordCount] = useState(0);
  const [animationCount, setAnimationCount] = useState(0);

  const keyword = ["Get", "Find", "Give", "Provide"];
  const animation = ["zoom-in", "zoom-stay", "zoom-out"];

  const animateTiming = () => {
    setAnimationCount((animationCount) => animationCount + 1);
  };
  useEffect(() => {
    const id = setInterval(animateTiming, 400);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (animationCount >= 3) {
      setAnimationCount(0);
      setKeywordCount((keywordCount) => (keywordCount + 1) % 4);
    }
  }, [animationCount]);

  const getAnimationClassName = () => {
    return animation[animationCount % 3];
  };


  const getKeyword = () => {
    return keyword[keywordCount];
  };

  return (
    <div className="find-help-animation">
      <div className="left-find-help">
          <div id="moving-text" className={getAnimationClassName()}>
            {getKeyword()}
          </div>
      </div>
      <div className="right-find-help">Help</div>
    </div>
  );
};

export default Animation;
