import React from "react";
import "./styles.scss";

const BackButton = (props) => {
  const { onClick } = props;
  return (
    <div className="generic-container-back-button__container" onClick={onClick}>
      <div className="generic-container-back-button__back-button">
        <i className="fa-solid fa-chevron-left"></i>
      </div>
    </div>
  );
};

export default BackButton;
