import React from "react";
import "../styles.scss";
import ImageSrc from "../../../static/images/toberemoved/garden.jpg";

const AdvertisementBar = () => {
  return (
    <div className="advertisement bar">
      <div className="image_container">
        <img className="image" src={ImageSrc}></img>
      </div>
      <div className="details_container">
        <div className="name">Ad details</div>
        <div className="seen">Today at 12:56</div>
      </div>
    </div>
  );
};

export default AdvertisementBar;
