import {createSlice} from "@reduxjs/toolkit";

const initialState =
{
   users: {},
   userImage: {},
   jobImage: {},
};

const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {
        saveUserDetails: (state, actions) => {
            state.users[actions.payload.uid] = actions.payload.data;
        },
        saveUserImageURL: (state, actions) => {
            state.userImage[actions.payload.uid] = actions.payload.url;
        },
        saveJobImagePrimaryURL: (state, actions) => {
            state.jobImage[actions.payload.jobUUID] = actions.payload.primaryImageURL;
        },
    }
});

export const {saveUserDetails, saveUserImageURL, saveJobImagePrimaryURL} = cacheSlice.actions;

export default cacheSlice.reducer;