export const SIGN_UP_MODAL = "SIGN_UP_MODAL";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const WELCOME_MODAL = "WELCOME_MODAL";


//lazy ones
export const UPDATE_PHONE_MODAL = "UPDATE_PHONE_MODAL";
export const UPDATE_EMAIL_MODAL = "UPDATE_EMAIL_MODAL";
export const JOB_LISTING_MODAL = "JOB_LISTING_MODAL";
export const JOB_POSTING_MODAL = "JOB_POSTING_MODAL";

// settings - lazy
export const UPDATE_PERSONAL_DETAILS_MODAL = "UPDATE_PERSONAL_DETAILS_MODAL";
export const UPLOAD_PROFILE_IMAGE_MODAL = "UPLOAD_PROFILE_IMAGE_MODAL";