import { initializeApp } from "firebase/app";
import {
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { store } from "../state/store";
import { getAdvtImageURL, getProfileImageURL } from "./utils";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Cloud Storage and get a reference to the service

// Create a child reference
// const advtRef = ref(storage, "advertisements/");

// const adctChildRef = ref(advtRef, "image-path");

export const uploadProfileImage = (
  fileBlob,
  onCompleteHandler,
  onFailureHandler
) => {
  const storage = getStorage();
  
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const metadata = { cacheControl: "public, max-age=86400" };

  if (userId) {
    const profileRef = ref(storage, getProfileImageURL(userId));

    uploadBytes(profileRef, fileBlob, metadata)
      .then(onCompleteHandler)
      .catch(onFailureHandler);
  }
};

export const getProfileImageSrcURL = (
  uuid,
  onCompleteHandler,
  onFailureHandler
) => {
  const storage = getStorage();

  if (uuid) {
    const profileRef = ref(storage, getProfileImageURL(uuid));
    try {
      getDownloadURL(profileRef).then(onCompleteHandler).catch(onFailureHandler);
    } catch (error) {
      console.log(error);
    }

  }
};

export const uploadAdvtImage = (
  fileUUID,
  advtStorageUUID,
  fileBlob,
  onCompleteHandler,
  onFailureHandler
) => {
  const storage = getStorage();
  // const state = store.getState();
  // const userId = state?.global?.user?.uid;
  const metadata = {
    cacheControl: "public,max-age=86400",
  };

  if (advtStorageUUID && fileUUID && fileBlob) {
    const profileRef = ref(storage, getAdvtImageURL(advtStorageUUID, fileUUID));

    uploadBytes(profileRef, fileBlob, metadata)
      .then((value) => {
        onCompleteHandler();
        console.log(value);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const getAdvtImageSrcURL = (
  filename,
  advtStorageUUID,
  onCompleteHandler,
  onFailureHandler
) => {
  const storage = getStorage();

  if (advtStorageUUID) {
    const profileRef = ref(storage, getAdvtImageURL(advtStorageUUID, filename));
    getDownloadURL(profileRef).then(onCompleteHandler).catch(onFailureHandler);
  }
};

export const getAdvtImageListURL = (advtStorageUUID) => {
  const storage = getStorage();

  const listRef = ref(storage, getAdvtImageURL(advtStorageUUID, ""));

  // Find all the prefixes and items.

  return new Promise((resolve, reject) => {
    listAll(listRef)
      .then((res) => {
        // res.prefixes.forEach((folderRef) => {
        //   // All the prefixes under listRef.
        //   // You may call listAll() recursively on them.
        // });
        const downloadUrls = res.items.map((itemRef) => 
          // this is going to be a set of
          
          new Promise((resolve, reject) => getDownloadURL(itemRef)
            .then((value) => {
              resolve(value);
            })
            .catch((error) => {
              reject(error);
            }))
      );

        resolve(downloadUrls);
      })
      .catch((error) => {
        reject(error);
        // Uh-oh, an error occurred!
      });
  });
};

// export const uploadProfileImage = (
//     filename,
//     fileBlob,
//     onCompleteHandler,
//     onFailureHandler
//   ) => {
//     const state = store.getState();
//     const userId = state?.global?.user?.uid;

//     if (userId) {
//       const advtRef = ref(storage, getProfileImageURL(userId));

//       uploadBytes(advtRef, fileBlob)
//         .then(onCompleteHandler)
//         .catch(onFailureHandler);
//     }
//   };
