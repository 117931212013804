import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/slices/globalmodalslice";
import "./styles.scss";
import clsx from "clsx";

const GenericModal = (props) => {
  const { closeButton, closeButtonFn, children, title, header, messageModal } = props;
  const dispatch = useDispatch();
  return (
    <div className={clsx("modal", "modal-open", {"message-modal": messageModal})}>
      <div className="modal-inner">
        <div className="modal-content">
          {closeButton && (
            <div
              onClick={() => {
                closeButtonFn ? closeButtonFn() : dispatch(closeModal());
              }}
              className="modal-close-icon"
            >
              <a className="close-modal">
                <i className="fa fa-times" aria-hidden="true"></i>
              </a>
            </div>
          )}
          <div className="modal-content-inner">
            {title && (
              <>
                <h1>{title}</h1>
                <hr />
              </>
            )}
            {header && (
              <>
                <h2>{header}</h2>
                <hr />
              </>
            )}

            <h6></h6>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
