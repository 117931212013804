import React from "react";
import "./styles.scss";
import {useSelector } from "react-redux";
import LargeCardButton from "../largecardbutton";
import { CREATE_A_JOB, PROVIDE_HOME, LIST_OF_JOBS_BREADCRUMB, LIST_POSTED_JOBS } from "./utils/constants";
import ListOfJobs from "./components/ListOfJobs";

const ProvideHomeScreen = (props) => {
  const { style } = props;
  const breadcrumb = useSelector((state) => state.homepage.providePageBreadcrumb);

  return (
    <div style={style} className="provide-home-screen__container">
      <div className="generic-container">
        {breadcrumb === PROVIDE_HOME && (
          <>
            <h4>Provide...</h4>
            <div className="slide-to-visible">
              <div className="provide-home-screen__grid">
                <LargeCardButton element={CREATE_A_JOB} />
                <LargeCardButton element={LIST_POSTED_JOBS} />
              </div>
            </div>
          </>
        )}
        {
          breadcrumb === LIST_OF_JOBS_BREADCRUMB && <ListOfJobs />
        }
      </div>
    </div>
  );
};

export default ProvideHomeScreen;
