import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: null,
  isLoading: { state: false },
  bottomNavBarVisible: true,
  navBarOpen: false,
  notificationBar: { state: true, data: { color: "", message: "hello" } },
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    saveConfig: (state, actions) => {
      state.config = actions.payload;
    },
    setLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    // saveUserCreds: (state, actions) => {
    //   state.user = actions.payload;
    // },
    toggleBottomNavBarVisibility: (state) => {
      state.bottomNavBarVisible = !state.bottomNavBarVisible;
    },
    setFirstLoginData: (state, actions) => {
      state.firstlogindata = actions.payload;
    },
    toggleNavBarOpen: (state) => {
      state.navBarOpen = !state.navBarOpen;
    },
    updateNotificationBar: (state, actions) => {
      state.notificationBar = actions.payload;
    }
  },
});

export const {
  saveConfig,
  setLoading,
  // saveUserCreds,
  toggleBottomNavBarVisibility,
  setFirstLoginData,
  toggleNavBarOpen,
  updateNotificationBar
} = globalSlice.actions;

export default globalSlice.reducer;
