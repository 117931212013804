import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openChatId } from "../../../state/slices/chatslice";
import "../styles.scss";
import ChatListItem from "./ChatListItem";
import { getAllChats } from "../../../firecloudfn/onRequest";
import useChatList from "../../../hooks/chat/useChatList";

const ChatList = () => {
  const dispatch = useDispatch();
  // const [listOfChats, setListOfChats] = useState([]);
  const listOfChats = useChatList();
  useEffect(() => {
    // getAllChats((data) => 
      // setListOfChats(data)
      // );
  }, []);

  const onClickHandler = (chat) => {
    dispatch(openChatId(chat.chatMessageRecord));
  };

  return (
      <div className="contacts">
        {listOfChats.map((chat) => (
          <ChatListItem
            chat={chat}
            onClickHandler={() => {
              onClickHandler(chat);
            }}
          />
        ))}
      </div>
  );
};

export default ChatList;
