import {createSlice} from '@reduxjs/toolkit';

const initialState =
{
    openChatId: ""
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        openChatId: (state, actions) => {
            state.openChatId = actions.payload;
        },
    }
});

export const {openChatId} = chatSlice.actions;

export default chatSlice.reducer;