import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { findUsersWithSkills } from "../../firedb";
import MapView from "../mapview";
import Button from "../button";
import SearchDropDown from "../searchdropdown";
import "./styles.scss";

const PersonItem = (props) => {
  const {person} = props;

  return <tr className="home-screen__notification_card">
    <td>{person.name}</td>
    <td>{person.location}</td>
    <td>{person.rating}</td>
  </tr>;
};

const FindHomeScreen = (props) => {
  const { style } = props;
  const [skillname, setSkillName] = useState("");
  const [skillnameResponse, setSkillNameResponse] = useState([]);
  // const suburb = useSelector((state) => state.user.user.suburb);

  const findOnClickHandler = () => {

    const fetch = async () => {
      const pincode = document.getElementById("find-home-screen-suburb-location").value;
      const skillsFromDB = await findUsersWithSkills({ skillname, pincode });

      if (skillsFromDB) {
        if (skillsFromDB.exists()) {
          const skillsValuesFromDB = skillsFromDB.val();
          setSkillNameResponse(Object.values(skillsValuesFromDB));
        } else {
          setSkillNameResponse([]);
          console.log("nothing exists in the db");
        }
      }
    };

    if (skillname.length > 2) fetch();
  };

  const selectedItemHandler = (element) => {
    setSkillName(element);
  } 

  return (
    <div style={style} className="find-home-screen-container">
      <h4>Find people with skills</h4>
      <div>What do you want to get done today?</div>
      <div className="slide-to-visible">
        <div>
          <span> <SearchDropDown className="slide-to-visible" selectedItem={selectedItemHandler} value={skillname} onChangeHandler={(value) => {setSkillName(value)}} createSkillOption={false} clearOnSelect={false}/></span>
          {/* <span><input
            onChange={findSkillOnChangeHandler}
            value={skillname}
            type={"search"}
          ></input></span> */}
          <span> @ </span>
          <span><input
            id="find-home-screen-suburb-location"
            // readOnly
            // value={suburb}
            type={"search"}
          ></input></span>
        </div>
        <div><Button title="Find" onClick={findOnClickHandler}/></div>
        <div> {JSON.stringify(skillnameResponse)}</div>
        {/* <MapView /> */}
        <div className="home-screen-notification__container">
        <div className="home-screen-things-jobs-container__notification_text">Notifications</div>
        <table>{skillnameResponse.map((element) => <PersonItem person={element} />)}
        </table>
      </div>
      </div>
    </div>
  );
};

export default FindHomeScreen;
