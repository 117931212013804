import React, { useEffect, useState } from "react";
// import HomeScreenNotificationCard from "./components/HomeScreenNotificationCard";
import "./styles.scss";
import { isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { displayJobPostingModal } from "../../state/slices/globalmodalslice";
import { ref, getDatabase } from "firebase/database";
import { useList, useObjectVal } from "react-firebase-hooks/database";
import { AREA_JOBLIST } from "../../firedb/constants";
import useGetJobsInArea from "../../firedb/hooks/useGetJobsInArea";
import HomeScreenJobItem from "../joblistitem/HomeScreenJobItem";
import { getJobsInArea } from "../../firecloudfn/onCall";
import { getNearJobs } from "../../firecloudfn/onRequest";
import PopoverMenu from "../popovermenu";
import { PROFILE_INFO, UPDATE_PHONE_NUMBER, UPLOAD_PHOTO, VERIFY_EMAIL } from "../settingshomescreen/utils/constants";
import SettingsCard from "../settingscard/SettingsCard";
import HomeHomeScreenLeftBar from "./components/HomeHomeScreenLeftBar";



const HomeHomeScreen = () => {
  const user = useSelector((state) => state.user.user);

  const [jobsInArea, setJobsInArea] = useState([]);

  useEffect(()=>{
    getNearJobs(
      (response) => {
        const values = Object.values(response)
        setJobsInArea(values);
      });
    }, []);
 
  return (
    <div className="home-home-screen__container">

      <HomeHomeScreenLeftBar />
      <div className="generic-container">

      <div className="home-screen-main-message">
        Help Exchange is platform to connect people who need a job with people
        who need someone to do a job.
      </div>
      <div className="home-screen-notification__container slide-to-visible">
        <div className="home-screen-notification__header">
          Notifications
        </div>

        <div className="home-screen-notification__grid">
        <SettingsCard element={PROFILE_INFO}/>
        <SettingsCard element={UPDATE_PHONE_NUMBER} />
        <SettingsCard element={VERIFY_EMAIL} />
        <SettingsCard element={UPLOAD_PHOTO} />

        </div>
      </div>
      <div className="home-screen-things-jobs-container slide-to-visible">
        <div className="home-screen-things-jobs-container__notification_text">
          Jobs near you
        </div>
        {jobsInArea.map((job) => (
          <HomeScreenJobItem job={job} />
        ))}

      </div>

    </div>
    <div className="home-home-screen_right-container"></div>

    </div>
  );
};

export default HomeHomeScreen;
