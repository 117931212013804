import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { saveUserDetails, saveUserImageURL } from "../../state/slices/cacheslice";
import { useDispatch, useSelector } from "react-redux";
import { getProfileImageSrcURL } from "../../firestorage";

//https://stackoverflow.com/questions/71013399/react-firebase-read-data-results-in-too-many-renders
const useUserProfileImageURL = (uid) => {
  
  const dispatch = useDispatch();
  
  const imageURL = useSelector((state) => state?.cache?.userImage?.[uid]);
    
  useEffect(() => {
    if (uid && !imageURL) {
      getProfileImageSrcURL(uid, (url) => {
        dispatch(saveUserImageURL({uid, url}));
      },
      () => {
        console.error("no image found at for "+ uid);
      }
      );
    } 
  }, []);

  return imageURL;

};

export default useUserProfileImageURL;
