import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { initializeApp } from "firebase/app";
import axios from "axios";
import firebaseConfig from "../config/firebaseConfig";
import { getAuth } from "firebase/auth";
import { CREATE_NEW_CHAT, CREATE_NEW_CHAT_V2, JOB } from "./axios/endpoints";

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const auth = getAuth(app);

connectFunctionsEmulator(functions, "localhost", 5001);

const getFnAxios = () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser
    .getIdToken()
    .then((token) => {
      axios.get("https://us-central1-aksinghs.cloudfunctions.net/aksinghs/us-central1/auth/hello", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    })
    .then((response) => {
      // handle success
      console.log(response);
    })
    .catch((error) => {
      // handle error
      console.log(error);
    })
    .finally(() => {
      // always executed
    });
};

export const createANewJobListing = (data) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser.getIdToken().then((token) => {
    axios
      .post("https://us-central1-aksinghs.cloudfunctions.net/job", data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // handle success
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  });
};

export const getNearJobs = (onComplete, onFailure) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser.getIdToken().then((token) => {
    axios
      .get(JOB, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // handle success
        onComplete(response.data);
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  });
};

export const getAllChats = (onComplete, onFailure) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser.getIdToken().then((token) => {
    axios
      .get("https://us-central1-aksinghs.cloudfunctions.net/aksinghs/us-central1/chat", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // handle success
        onComplete(response.data);
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  });
};
export const createNewChat = (data, onComplete, onFailure) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser.getIdToken().then((token) => {
    axios
      .post(CREATE_NEW_CHAT_V2, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // handle success
        onComplete(response);
        console.log(response);
      })
      .catch((error) => {
        // handle error
        onFailure(error);
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  });
};

export const deleteChat = (data, onComplete, onFailure) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  auth.currentUser.getIdToken().then((token) => {
    axios
      .delete("https://us-central1-aksinghs.cloudfunctions.net/aksinghs/us-central1/chat/" + data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        // handle success
        onComplete(response.data);
        console.log(response);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
  });
};
