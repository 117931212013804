import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { getAdvtImageSrcURL } from "../../firestorage";
import { getAuth } from "firebase/auth";
import { displayModal } from "../../state/slices/globalmodalslice";
import { JOB_LISTING_MODAL } from "../../state/modal-constants";
import moment from "moment";

const HomeScreenJobItem = (props) => {
  const dispatch = useDispatch();

  const details = props.job;
  const [advtImgSrc, setAdvtImgSrc] = useState();

  useEffect(() => {
    const mainImageUUID = JSON.parse(details.imageFileNames).primary;
    if (mainImageUUID) {
      getAdvtImageSrcURL(mainImageUUID, details.storageUUID, (value) => {
        setAdvtImgSrc(value);
      });
    }
  }, [details]);

  const auth = getAuth();
  const uuid = auth.currentUser.uid;

  const getPostedByUser = () => {
    if (details?.createdBy?.user?.uid === uuid) {
      return "You";
    } else {
      return `${details?.createdBy?.user?.details?.firstname} ${details?.createdBy?.user?.details?.lastname}`;
    }
  };

  return (
    <div className="helpexch-job-item__container">
      <div className="helpexch-job-item__image-container">
        <img className="helpexch-job-item__image" src={advtImgSrc} />
      </div>
      <div className="helpexch-job-item__header-container">
        <div className="helpexch-job-item__header-text">{details.jobTitle}</div>
      </div>
      <div className="helpexch-job-item__description-container">
        <div className="helpexch-job-item__details small">Posted By:</div>
        <div className="helpexch-job-item__details">{getPostedByUser()}</div>
        <div className="helpexch-job-item__details italics">New User</div>
        <div className="helpexch-job-item__details">Clyde North</div>
      </div>
      <div className="helpexch-job-item__duration-container">
        <div className="helpexch-job-duration__icon">
          
          <i className="fa-regular fa-calendar"></i>
        </div>

        <div className="helpexch-job-duration__text">
          {moment(details.creationDate).fromNow()}
        </div>
      </div>
      <div className="helpexch-job-item__button-container">
        <button
          onClick={() => {
            dispatch(displayModal({ modal: JOB_LISTING_MODAL, data: details }));
          }}
        >
          Details
        </button>
      </div>
      <div className="helpexch-job-item__ellipse-container">
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </div>
    </div>
  );
};

export default HomeScreenJobItem;
